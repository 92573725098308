<template>
  <div class="mb-5 matching-skills">
    <div class="container">
      <div class="row align-items-center" :class="{'tw-opacity-60 tw-cursor-not-allowed': isLoading}">
        <div class="col-md-12" v-if="skills.length > 0 && isResponse">
          <CarouselOwl
            :items="6"
            :loop="false"
            :margin="10"
            :nav="true"
            :autoWidth="true"
            :dots="false"
            :responsive="{
              0: {
                items: featureId4? 1 : 3,
              },
              576: {
                items: featureId4? 1 : 2,
              },
              768: {
                items: 3,
              },
              1024: {
                items: 5,
              },
            }"
            class="matching-skills-carousel Skills-carousel"
            :class="{ 'Skills-carousel2': featureId4}"
          >
            <skill-card
              v-for="(skill, i) in skills"
              :key="i"
              :skill="skill"
              :class="{'tw-opacity-60 !tw-cursor-not-allowed': isLoading}"
              @handleSkillClick="handleSkillClick($event)"
              :active="activeSillParent"
            />
          </CarouselOwl>
        </div>

        <div
          v-if="!isResponse"
          class="matching-skills-carousel Skills-carousel"
        >
          <div class="d-flex align-items-center justify-content-center">
            <skeleton-loader-vue
              type="rect"
              :width="800"
              :height="30"
              animation="fade"
              rounded
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CarouselOwl from 'vue-owl-carousel';
import skillCard from "../skillCard/skillCard.vue";
import "./carousel.scss";
export default {
  components: { CarouselOwl, skillCard },
  props: ["skills", "isResponse", "isLoading","featureId4"],
  data() {
    return { activeSillParent: 0 };
  },
  watch: {
    isResponse: {
      immediate: true,
      deep: true,
      handler() {
      
      this.activeSillParent = this.skills[0]?.id;
      }
    },
    skills(newSkills) {
      // get the role query parameters from the url vue
      const roleID = Number(this.$route.query.role);
      this.activeSillParent = roleID ? roleID : newSkills[0]?.id;
      if (roleID) {
        this.$emit("handleSkillClick", roleID);
        this.$router.replace({ query: {} });
      }
    },
  },
  methods: {
    handleSkillClick(id) {
      if (!this.isResponse) return;
      this.activeSillParent = id;

      this.$emit("handleSkillClick", id);
    },
  },
};
</script>

<style lang="scss" >
.owl-carousel .owl-item {
  display: flex;
  margin-bottom: 2px;
  @media (max-width: 576px) {
    margin: 10px;
  }
}

.Skills-carousel2 .item {
    display: flex !important;
    line-height: 18px;
    height: 1rem !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 18px 15px !important;
}

.Skills-carousel2 .owl-stage-outer {
   margin: 0 0.5rem;
}
</style>

<style lang="scss" scoped>
.matching-skills {
  @media (max-width: 576px) {
    margin-bottom: 1rem !important;
  }
}
</style>