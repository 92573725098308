<template>
  <transition name="modal-fade">
    <div class="modal align-items-center request-an-interview-modal" role="dialog" aria-labelledby="modalTitle"
      aria-describedby="modalDescription" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <!--  <div class="border-0 modal-header justify-content-center">
            <button type="button" class="btn-close" @click="close"></button>
          </div> -->

          <div class="tw-pb-4 modal-body no-border">
            <div class="row">
              <div class="tw-text-start col-12 meetBox" v-show="isLoading">
                <div class="p-0 tw-mb-4 tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-items-center">
                  <div class=" tw-w-full md:tw-w-2/3">
                    <h5 class="tw-mb-0 tw-text-[#202020] tw-text-base tw-font-semibold tw-leading-loose">

                      {{ $t('requestInterview.mainHeading') }}
                    </h5>
                    <p class=" tw-text-[#422e87] tw-mb-2 tw-text-xs tw-font-normal tw-leading-relaxed ">
                      {{ $t('requestInterview.secondaryHeading') }}
                    </p>
                  </div>

                  <div class=" tw-w-full md:tw-w-[30%] tw-relative">
                    <v-select placeholder="Select a timezone" v-model="selectedTimezone" :options="timezones"
                      :clearable="false" class="style-chooser tw-w-full " label="name" :reduce="(name) => name.value">
                    </v-select>
                  </div>

                </div>

                


                <div class="">
                  <div class="ltr">
                    <Calendar ref="datePickerRef" :disabled-dates='{ weekdays: [6, 7] }'
                    v-model="selectedDate"
                      :min-date="new Date(Date.now() + 2 * 24 * 60 * 60 * 1000)" :attributes="attributes"
                     :trim-weeks="false" is-expanded @dayclick="onDayClick" />
                  </div>
                  <div class="tw-mt-5">
                    <div class="tw-flex tw-flex-col tw-justify-items-start tw-mb-5" v-for="(day, i) in days" :key="i">
                      <div class="tw-flex tw-flex-row tw-items-center tw-justify-between tw-w-full">
                        <div class="tw-flex md:tw-flex-row tw-flex-col ">
                          <span class="tw-text-[#484d54] tw-text-base tw-leading-relaxed tw-me-2">
                            {{ $t('requestInterview.selectTimeFor') }}
                          </span>
                          <span class="tw-text-[#484d54] tw-text-base tw-font-semibold tw-leading-relaxed">
                            {{ dataFormat(day.date) }}</span>
                        </div>

                        <div
                          class="time-slot tw-flex tw-flex-row tw-border-[#C2C2C2]  tw-border-solid tw-rounded-lg tw-py-2 tw-px-3  tw-border  ">
                          <DatePicker mode="time" v-model="day.time" :valid-hours="getValidHours[i]" class="ltr"
                            :minute-increment="30" is24hr />
                          <img src="@/assets/images/icons/time.svg" alt="time" />
                        </div>
                      </div>
                      <VulidateError class="tw-flex" :validationField="$v.days.$each[i].time" :params="[
                        'required',
                      ]" />
                    </div>

                  </div>
                </div>
                <div v-if="error" class="tw-text-red-500 tw-text-sm tw-mt-2 ">

                  {{ days.length == 2 ? $t('requestInterview.onlySelectTwoTimeSlots') :
                    $t('requestInterview.selectTwoTimeSlots')}}
                </div>
                <div class="modal-footer tw-p-0 ">
                  <button type="button" class="btn poppins-font cancelbtn btnstyleUpdate" :disabled="isLoadingRequest"
                    data-bs-dismiss="modal" @click="close">

                    {{ $t('requestInterview.cancel') }}
                  </button>
                  <button type="button" class="btn btn-primary saveBtn" :disabled="isLoadingRequest" @click="save()">

                    {{ $t('requestInterview.submit') }}
                  </button>
                </div>
              </div>
            </div>
            <div class="text-center col-12 meetBox d-flex flex-column justify-content-center" v-if="!isLoading">
              <div class="d-flex">
                <skeleton-loader-vue type="rect" :width="800" :height="30" animation="fade" rounded />
              </div>

              <br />
              <br />

              <div class="w-full m-auto d-flex justify-content-center" v-for="index in 10" :key="index">
                <skeleton-loader-vue class="mb-4" type="rect" :height="30" :width="500" animation="fade" rounded />
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { DatePicker, Calendar } from "v-calendar";
import moment from "moment";
import momentTimezone from "moment-timezone";
import { required } from "vuelidate/lib/validators";
import VulidateError from "@/components/shared/VulidateError/vulidateError";

export default {
  name: "SelectInterviewSlotsModal",
  props: ["isOpen"],
  components: {
    DatePicker,
    Calendar,
    VulidateError
  },
  validations: {
    days: {
      $each: {
        time: {
          required,
        },
      },
    },
  },
  data() {
    return {
      isLoading: false,
      isLoadingRequest: false,
      selectedDate: null,
      days: [],
      time: new Date(),
      error: false,
      disabledDates: {
        weekdays: [6, 7],  // Example: Disable weekends
        dates: []
      },
      selectedTimezone: 'Africa/Cairo',
      timezones: [],
    };
  },
  mounted() {
    this.isLoading = true;
    this.checkTodayAvailability();
    this.loadTimezones();
  },
  watch: {
    selectedDate(newValue) {
      if (newValue) {
        console.log(newValue);
       
        
      }
    },
    isOpen: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue) {
          document.body.classList.add("modal-open");
        } else {
          document.body.classList.remove("modal-open");
        }
      },
    },
  },
  computed: {
    getValidHours() {
      return this.days.map((date) => {
        const validHoursUTC = [7, 8, 9, 10, 11, 12, 13, 14, 15];
        const validHoursLocal = validHoursUTC.map(hour => {
          const localHour = momentTimezone.tz(new Date(Date.UTC(1970, 0, 1, hour)), this.selectedTimezone).hour();
          return localHour;
        });

        const today = momentTimezone().tz(this.selectedTimezone).toDate();
        const selectedDate = momentTimezone(date).tz(this.selectedTimezone).toDate();
        if (today.toDateString() === selectedDate.toDateString()) {
          const currentHour = today.getHours();
          return validHoursLocal.filter(hour => hour > currentHour);
        }
        return validHoursLocal;
      });
    },
    dates() {
      return this.days.map((day) => day.date);
    },
    attributes() {
      return this.dates.map((date) => ({
        highlight: true,
        key: date.toDateString(),
        dates: date,
      }));
    },
  },
  methods: {
    loadTimezones() {
      this.selectedTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      let timezones = momentTimezone.tz.names();

      timezones = timezones.map(timezone => {
        const gmtOffset = momentTimezone().tz(timezone).format('Z').replace(':00', '')
        return {
          name: `${timezone.split('/')[1]} (GMT${gmtOffset})`,
          value: timezone
        };
      });
      this.timezones = timezones;
    },
    getValidHoursInToday(date) {
      const validHoursUTC = [7, 8, 9, 10, 11, 12, 13, 14, 15];
      const validHoursLocal = validHoursUTC.map(hour => {
        const localHour = momentTimezone.tz(new Date(Date.UTC(1970, 0, 1, hour)), this.selectedTimezone).hour();
        return localHour;
      });

      const today = momentTimezone().tz(this.selectedTimezone).toDate();
      const selectedDate = new Date(date);
      if (today.toDateString() === selectedDate.toDateString()) {
        const currentHour = today.getHours();
        return validHoursLocal.filter(hour => hour > currentHour);
      }
      return validHoursLocal;
    },
    checkTodayAvailability() {
      const today = new Date();
      const availableSlots = this.getValidHoursInToday(today);

      // If no available slots, disable today
      if (availableSlots.length === 0) {
        this.disabledDates.dates.push(today); // Add today's date to the disabled dates
      }
    },
    dataFormat(date) {
       if (this.$i18n.locale == "en") {
        moment.locale("en")
         return moment(date).format('D MMM')
       } else {
        moment.locale("ar")
         return moment(date).format('D MMM')
       }
    },
    save() {
      this.isLoadingRequest = true;
      this.error = false;
      if (this.days.length !== 2) {
        this.error = true
        this.isLoadingRequest = false;
        return;
      }
      this.$v.$touch();
      if (this.$v.days.$invalid) {
        this.isLoadingRequest = false;
        return;
      }
       moment.locale("en")
      const payload = {
        "sharable_code": this.$route.params.id,
        "slots": this.days.map(day => {
          const date = moment(day.date).format('YYYY-MM-DD');
          const time = moment(day.time).format('HH:mm');
          return `${date} ${time}`;
        }),
        "timezone": this.selectedTimezone
      }

      this.axios.post('api/client/interviews', payload).then(() => {
        this.$emit("intervewRequested", payload);
        this.isLoadingRequest = false;
        this.close();
      }).catch(error => {
        this.isLoadingRequest = false;
        console.log(error)
      })


    },
    onDayClick(day) {
      if (day.isDisabled) return;
     
      const idx = this.days.findIndex((d) => d.id === day.id);
      if (idx >= 0) {
        this.days.splice(idx, 1);
        this.$nextTick(() => {
          document.body.click();
        });
      } else {
        if (this.days.length < 2) {
          this.days.push({
            id: day.id,
            date: day.date,
            time: null,
          });
        } else {
          this.error = true;
        }
      }
    },
    close() {
      document.body.classList.remove("modal-open");
      this.isRequestAnInterviewLoading = false;
      this.$emit("update", false);
    },
  },
};
</script>

<style scoped>
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 45rem;
    width: 45rem;
    margin: 1.75rem auto;
  }
}
</style>


<style lang="scss">
.request-an-interview-modal {
  .ltr {
    direction: ltr;
  }

  .vs__selected-options {
    height: 50px;
  }

  .vs__selected {
    height: 50px;
    font-size: 14px;
  }

  .vc-container {
    box-shadow: #0000004a 0px 0px 0px 0px !important;
    border: 1px solid #f5f5f5;
  }

  .vc-weeks {
    height: 22rem;
  }

  .vc-arrows-container {
    width: 55%;
    left: 50%;
    transform: translate(-50%, 0);
  }

  .vc-arrow {
    color: #ffc629;
    font-size: 1.5rem;
  }

  .vc-title {
    color: #161616;
    font-size: 22px;
    font-weight: 700;
  }

  .vc-highlight {
    background-color: #ffc629 !important;
    color: #fff !important;
    border-radius: 14px !important;
    width: 2.2rem !important;
    height: 2.2rem !important;
  }

  .vc-day-content:hover {
    background-color: #ffc629 !important;

    color: #fff !important;
    border-radius: 14px !important;
    width: 2.2rem !important;
    height: 2.2rem !important;
  }

  .vc-day-content:focus:not(:hover):not(.vc-highlight) {
    background-color: #ffc6291c !important;
    color: #000 !important;
    border-radius: 14px !important;
    width: 2.2rem !important;
    height: 2.2rem !important;
  }

  .time-slot {
    .vc-date {
      display: none;
    }

    .vc-time-icon {
      display: none;
    }

    .vc-container {
      border: none;
    }

    .vc-select select {
      background-color: #fff;
      border: none;
      width: auto;
      padding: 0 8px 0 8px;
    }

    .vc-select-arrow {
      display: none;
    }

    .vc-am-pm button.active {
      background-color: #ffc629;
      color: #fff;
    }

    .vc-am-pm button.active:focus {
      border-color: #ffc629;
    }

    .vc-am-pm button.active:hover {
      background: #ffc629;
    }
  }
}

.modal-open {
  overflow: hidden;
}

.book-meeting-mb {
  min-height: 500px;

  @media (max-width: 786px) {
    margin-bottom: 5rem;
  }
}

.book-meeting-mb .calendly {
  min-height: 500px;
  height: 500px !important;
}

.book-meeting-mb .calendly iframe {
  min-height: 500px;
}

.meetBox .w-full {
  width: 100% !important;
}
</style>
