<template>
    <div class="btn-group">
        <button
            v-if="clientName || activeProject"
            type="button"
            class="border-0 btn dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
        >
            {{ clientName || activeProject?.name }} 
        </button>
        <ul class="dropdown-menu dropdown-menu-end">
            <template v-if="projects.length > 1">
                <li v-for="project in projects" :key="project.id" class="project-item">
                    <button
                        class="border-white dropdown-item"
                        type="button"
                        @click="$emit('project-changed', project)"
                    >
                        <img loading="lazy" :src="`https://placehold.co/35x35?text=${projectFirstLetter(project)}`" alt="">
                        <span v-if="!!project.name">
                            {{ project.name }}
                        </span>
                        <div class="tw-flex tw-items-center" v-else>
                            <span :class="{ 'tw-h-4': $i18n.locale !== 'en' }" >Unnamed squad</span>
                            <div class="tooltip-wrapper" :data-message="$t('home.clientMissingProjectNameInfoMessage')">
                                <span>
                                    <img loading="lazy" src="@/assets/images/info-light.png" class=" !tw-w-3 !tw-h-3"   />
                                </span>
                            </div>
                        </div>
                        <span v-if="activeProject && activeProject.id == project.id" class="selected-mark">
                            ✓
                        </span>
                    </button>
                </li>
            </template>
            <template v-if="!notLoggedIn">
                <li>
                    <router-link
                        class="border-white dropdown-item"
                        to="/sq/client/matching"
                    >
                        {{ $t("matchedTalents.matchedTalents") }}
                    </router-link>
                </li>
                <li v-if="hasNomination">
                    <router-link
                        class="border-white dropdown-item"
                        to="/sq/client/nomination"
                    >
                        {{ $t("matchedTalents.nominations") }}
                    </router-link>
                </li>
                <li v-if="isClientHasContractRenewal">
                    <router-link
                        class="border-white dropdown-item"
                        to="/sq/client/contracts-renewal"
                    >
                        {{ $t("matchedTalents.squadRenewal") }}
                    </router-link>
                </li>
                <li v-if="isClientHasInterviews">
                    <router-link
                        class="border-white dropdown-item"
                        to="/sq/client/interviews-list"
                    >
                        {{ $t("matchedTalents.interviewsList") }}
                    </router-link>
                </li>
                <li>
                    <button
                        class="border-white dropdown-item"
                        type="button"
                        @click="logOut"
                    >
                        {{ $t("home.logout") }}
                    </button>
                </li>
            </template>
            <li v-if="hasNomination && uncompletedLead">
                <router-link
                    class="border-white dropdown-item"
                    to="/sq/client/nomination"
                >
                    {{ $t("matchedTalents.nominations") }}
                </router-link>
            </li>
            <li v-if="isClientHasContractRenewal && uncompletedLead">
                <router-link
                    class="border-white dropdown-item"
                    to="/sq/client/contracts-renewal"
                >
                    {{ $t("matchedTalents.squadRenewal") }}
                </router-link>
            </li>
            <li v-if="isClientHasInterviews && uncompletedLead">
                <router-link
                    class="border-white dropdown-item"
                    to="/sq/client/interviews-list"
                >
                    {{ $t("matchedTalents.interviewsList") }}
                </router-link>
            </li>
            <li v-if="uncompletedLead">
                <button
                    class="border-white dropdown-item"
                    type="button"
                    @click="logOut"
                >
                    {{ $t("home.logout") }} 
                </button>
            </li>
        </ul>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    props: {
        clientName: {
            type: String,
            required: true,
        },
        hasNomination: {
            type: Boolean,
            default: false,
        },
        isClientHasContractRenewal: {
            type: Boolean,
            default: false,
        },
        notLoggedIn: {
            type: Boolean,
            default: false,
        },
        uncompletedLead: {
            type: Boolean,
            default: false,
        },
        projects: {
            type: Array,
            default: () => [],
        },
        activeProject: {
            type: Object,
            default: null,
        },
    },
    computed: {
        ...mapGetters({
            isClientHasInterviews: "auth/getClientHasInterviews"
        }),
    },
    methods: {
        projectFirstLetter(project) {
            if (project.name) return project.name[0].toUpperCase()
            return 'U';
        },
        logOut() {
            this.$store.dispatch("auth/logOut").then(() => {
                this.$router.push({ path: "/login" });
              //  window.location.reload();
            });
        },
    }
}
</script>

<style lang="scss" scoped>
.dropdown-menu {
    width: 242px;
    padding: 15px 7px;
}

.dropdown-item {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    img {
        width: 35px;
        height: 35px;
        border-radius: 100%;
        margin-inline-end: 10px;
    }

    span {
        font-size: 13px;
    }

    .tooltip-wrapper {
        position: relative;
        margin-inline-start: 5px;

        span {
            font-size: 18px;
        }

        &:before {
            content: attr(data-message);

            position: absolute;
            display: none;
            padding: 10px;
            border-radius: 7px;
            background-color: #000;
            color: #fff;
            font-size: 10px;
            right: 0;
            top: calc(-100% - 10px);
        }

        &:hover {
            &:before {
                display: block;
            }
        }
    }

    .selected-mark {
        margin-inline-start: auto;
        font-weight: 900;
    }
}

.project-item {
    border-bottom: 1px solid #eee;
}

html[dir="rtl"] {
    .tooltip-wrapper {
        &:before {
            left: 0;
            right: auto;
        }
    }
}
</style>