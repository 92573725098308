var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tw-w-full tw-flex"},[_c('div',{staticClass:"assessmentWrapper",staticStyle:{"margin-top":"0"}},[_c('div',{staticClass:"assessment-header"},[_c('div',{staticClass:"assessment-header-title"},[_vm._v(" "+_vm._s(_vm.item.assessmentData.name)+" ")]),(_vm.item.assessmentData.experience_years_text)?_c('div',{staticClass:"assessment-experience"},[_vm._v(" "+_vm._s(_vm.item.assessmentData.experience_years_text)+" "+_vm._s(_vm.item.assessmentData.experience_years_text == "0-1" ? _vm.$t('matchedTalents.yearExperience') : _vm.$t('matchedTalents.yearExperiences'))+" ")]):_vm._e()]),_c('div',{staticClass:"d-flex assessments-cards w-100"},_vm._l((_vm.item.assessments),function(assessment,i){return _c('div',{key:i,staticClass:"assessment-card",class:{ 'mr-2': i == 0 && _vm.item.assessments.length > 1 , 'card-50': _vm.item.assessments.length > 1 }},[_c('div',{staticClass:"assessment-card-number"},[_vm._v("0 "+_vm._s(i + 1))]),_c('div',{class:{ 'assessment-card-data': !assessment.is_taken }},[_c('div',{staticClass:"assessment-card-name"},[(assessment.type == 'mcq')?_c('span',[_vm._v(" "+_vm._s(_vm.$t('matchedTalents.MCQExam'))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('matchedTalents.problemSolving'))+" ")]),(false)?_c('span',[_vm._v(" "+_vm._s(assessment.type == "mcq" ? _vm.handleAssessmentName(_vm.item, i).length > 25 ? _vm.handleAssessmentName(_vm.item, i).substring(0, 25) + "..." : _vm.handleAssessmentName(_vm.item, i) : assessment.name)+" ")]):_vm._e()]),(assessment.is_taken)?_c('div',{staticClass:"assessment-card-score"},[_c('span',{staticClass:"assessment-card-score-number"},[_vm._v(_vm._s(assessment.percentage))]),_c('span',{staticClass:"assessment-card-score-number-total"},[_vm._v("/ 100 ")]),_c('span',{staticClass:"assessment-card-score-text"},[_vm._v(" "+_vm._s(_vm.$t("talent.totalScore"))+" ")])]):_vm._e(),(
              _vm.isWaitingTime &&
              assessment.type == 'code' &&
              !assessment.is_taken
            )?_c('p',{staticClass:"assessment-card-waiting-time"},[_vm._v(" "+_vm._s(_vm.$t( "talent.weAreReviewingAnswersToCalculateTheResultPleaseWait10min" ))+" ")]):_vm._e()]),_c('div',{staticClass:"assessment-card-action"},[(
              _vm.isWaitingTime &&
              assessment.type == 'code' &&
              !assessment.is_taken
            )?_c('img',{attrs:{"loading":"lazy","src":require("@/assets/images/waiting-time.svg")}}):_vm._e(),(
              !(
                _vm.isWaitingTime &&
                assessment.type == 'code' &&
                !assessment.is_taken
              ) && assessment.is_passed
            )?_c('img',{attrs:{"loading":"lazy","src":require("@/assets/images/icons/ic_success.svg")}}):_vm._e(),(
              !(
                _vm.isWaitingTime &&
                assessment.type == 'code' &&
                !assessment.is_taken
              ) &&
              !assessment.is_passed &&
              assessment.is_taken
            )?_c('img',{attrs:{"loading":"lazy","src":require("@/assets/images/icons/ic_cancel.svg")}}):_vm._e(),(
              !(
                _vm.isWaitingTime &&
                assessment.type == 'code' &&
                !assessment.is_taken
              ) && !assessment.is_taken
            )?_c('button',{staticClass:"btn",attrs:{"disabled":!assessment.will_be_taken,"id":`${i == 0 ? 'first-assessment' : ''}`},on:{"click":function($event){return _vm.goToTalentProfile(assessment.link)}}},[_vm._v(" "+_vm._s(_vm.$t("talent.start"))+" ")]):_vm._e()])])}),0),(!_vm.item.assessmentData.is_passed)?_c('div',{staticClass:"assessment-statement"},[_c('img',{staticClass:"assessment-statement__img",attrs:{"loading":"lazy","src":require("@/assets/images/ic_verifed.svg")}}),_c('p',[_vm._v(_vm._s(_vm.$t('talent.passAssesmentVerifiedTalent')))])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }