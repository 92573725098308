<template>
 <div :class="`googleBtn !tw-flex ${wrapperStyle}`">
    <button
    :class="`w-100 bg-white btn btn-white sign-with-google font-PoppinsSemiBold btnstyleUpdate !tw-flex tw-justify-center ${btnStyle}`"
    @click="handleGoogleAuth"
  >
    <img loading="lazy"
      src="../../../assets/images/google.png"
      :class="`mx-2 google-icon ${imgStyle}`"
    />
    {{ label ? label : 'Google' }}
  </button>
 </div>
</template>
<script>
import "./googleAuthBtn";
import store from "@/store";

export default {
  props: ["label", "btnStyle", "imgStyle", "type", "wrapperStyle", "in_competition", "meeting_reserved", "new_talent" ],
  methods: {
    handleGoogleAuth() {
      let payload={}
      if(this.type){
        payload.type = this.type
      }
      if(this.in_competition){
        payload.in_competition = 1
      }
      payload = {
        ...payload,
        marketing_campaign: this.$route.query.Campaign,
        signupSource: store.getters["shared/getUTMParameters"],
      }

      if(this.meeting_reserved){
        localStorage.setItem('meeting_reserved', true)
      }

      if(this.new_talent) {
        localStorage.setItem('new_talent', true)
      }

      this.$store.dispatch("auth/googleAuth", payload).then((res) => {
      if (window.gtag) window.gtag("event", "Talent sign-up submitted", {
          step_number: 'Step 1',
          signup_method: 'Google',
          first_name: res?.first_name,
          last_name: res?.last_name,
          phone_number: res?.phone,
          country: res?.country,
          email: res?.email
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
