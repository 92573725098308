<template>
    <div class="tw-space-y-4 new-slot">

        <div class="tw-flex tw-flex-col tw-justify-center tw-gap-2 md:tw-gap-4 tw-p-4 tw-space-y-2 tw-bg-white tw-rounded-3xl md:tw-px-14 md:tw-py-4"
            style="box-shadow: 11px 8px 56px rgba(66, 46, 135, 0.09)">
            <div class="tw-pb-4">
                <div class="tw-w-full">
                    <h2 class="tw-text-[#422e87] md:tw-text-3xl tw-text-lg tw-font-bold md:tw-leading-10 md:tw-mb-3">
                        {{  $t('requestInterview.talentProposedNewSlot')  }}
                    </h2>
                    <p class="tw-text-[#8d8d8d] md:tw-text-base tw-text-sm tw-font-normal tw-leading-relaxed tw-mb-0">
                       
                        
                        {{  $t('requestInterview.talentProposedNewSlotParagraph')  }}
                    </p>

                    <hr class="tw-w-full tw-h-[1px] tw-border tw-border-[rgba(0,0,0,0.19)]" />

                    <div class="tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-w-full tw-mb-7">
                        <div class="tw-text-[#422e87] md:tw-text-base tw-text-sm tw-font-bold tw-mb-2 md:tw-mb-0">
                            {{ $t("requestInterview.timeZone") }}
                        </div>
                        <div class="md:tw-w-1/3 tw-w-full">
                            <v-select placeholder="Select a timezone" v-model="selectedTimezone" :options="timezones"
                                :clearable="false" class="style-chooser tw-w-full" label="name"
                                :reduce="(name) => name.value">
                            </v-select>
                        </div>
                    </div>
                    

                    <div class="tw-flex tw-flex-col tw-space-y-4">
                        <div class="form-check d-flex" v-for="(option, i) in interviewSlots" :key="i">
                            <input class="form-check-input" type="radio" name="flexRadioDefault" :id="option.value"
                                v-model="selected" :value="option" :disabled="option.isPast" />
                            <label class="form-check-label tw-ms-2" :for="option.value">
                                <div v-if="option.value == 'Unavailable'">
                                    <div v-if="selected?.value === option.value">
                                        <span
                                            class="tw-text-[#1e2022] md:tw-text-[1.125rem] tw-text-[1rem] tw-font-medium tw-mt-1">
                                            {{ $t("requestInterview.clientUnavailable") }}
                                        </span>
                                        <span class=" tw-block tw-text-[#808285] tw-text-base tw-font-medium ">
                                            
                                            {{ $t("requestInterview.clientUnavailableInfo") }}
                                        </span>
                                    </div>
                                    <div v-else>
                                        <span
                                            class="tw-text-[#4f4e4e] md:tw-text-[1rem] tw-text-[0.825rem] tw-font-normal tw-mt-1">
                                            {{ $t("requestInterview.clientUnavailable") }}
                                        </span>
                                    </div>
                                </div>
                                <div v-else>
                                    <div v-if="selected?.value === option.value">
                                        <span
                                            class="tw-text-[#1e2022] md:tw-text-[1.125rem] tw-text-[1rem] tw-font-medium">
                                            {{ option.value }}
                                        </span>
                                        <span class="tw-text-black md:tw-text-[1rem] tw-text-[0.825rem] tw-font-medium">
                                            {{
                                                timezones.find((tz) => tz.value == selectedTimezone)
                                            .name
                                            }}
                                        </span>
                                    </div>
                                    <div v-else>
                                        <span
                                            class="tw-text-[#4f4e4e] md:tw-text-[1.125rem] tw-text-[1rem] tw-font-normal tw-leading-7">
                                            {{ option.value }} </span><span
                                            class="tw-text-[#979797] md:tw-text-[1rem] tw-text-[0.825rem] tw-font-normal tw-leading-7">
                                            {{
                                                timezones.find((tz) => tz.value == selectedTimezone)
                                            .name
                                            }}
                                        </span> 
                                    </div>
                                </div>
                            </label>
                        </div>
                    </div>

                    <div class="tw-flex tw-bg-[#c3b5f276] tw-px-4 tw-py-4 tw-mt-7 tw-rounded-2xl"
                        v-if="selected?.value == 'Unavailable' && false">
                        <img class="tw-w-4 tw-h-4 tw-me-2 tw-mt-1" src="@/assets/images/contract-renewal/info-icon.svg"
                            alt="info" />
                        <p class="tw-text-[#414042] tw-text-sm tw-font-medium tw-leading-relaxed">
                            {{ $t("requestInterview.unavailableInfo") }}
                        </p>
                    </div>

                    <div v-if="error" class="tw-text-red-500 tw-text-sm tw-mt-2">
                        {{ $t("requestInterview.selectTimeError") }}
                    </div>
                    <!-- Existing content here -->
                </div>
            </div>

            <div class="tw-flex tw-items-center tw-justify-end tw-w-full tw-gap-4 tw-my-auto">
            <button
              v-if="interview.status.id === 'client_pending_update' || interview.status.id === 'client_pending' || interview.status.id === 'talent_pending' || interview.status.id === 'talent_pending_update'"
              @click="$emit('cancel')"
              class="tw-px-4 tw-py-3 md:tw-py-3.5 tw-w-full md:tw-w-auto  md:tw-min-w-[10rem]  tw-rounded-lg  tw-justify-center tw-items-center tw-underline  tw-gap-2.5 tw-flex tw-text-center tw-text-[#ff6f59] tw-text-[12px]  md:tw-text-base tw-font-medium">
              {{ $t('requestInterview.cancelProcess')}}
            </button>
            <button
              :disabled="isLoading"
              v-if="interview.status.id === 'client_pending_update' || interview.status.id === 'client_pending' && interview.status.id !== 'talent_pending'"
              @click="confirm"
              class="tw-px-4 tw-py-3 md:tw-py-3.5 tw-w-full tw-bg-[#ffc629] md:tw-w-auto  md:tw-min-w-[10rem] tw-rounded-lg tw-border-[#d7d7d7] tw-justify-center tw-items-center tw-gap-2.5 tw-flex text-[#292929] tw-text-[15px]  md:tw-text-base tw-font-bold">
              
               {{ $t('requestInterview.submit')}}
            </button>
          </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import momentTimezone from "moment-timezone";

export default {
    name: "InterviewCycleProposedSlots",
    props: {
        interview: {
            type: Object,
            default: () => { },
        },
    },
    filters: {
        truncate(value) {
            return value.length > 35 ? value.substring(0, 35) + "..." : value;
        },
    },
    mounted() {
        this.getInterviewSlots();
    },
    data() {
        return {
            error: false,
            options: [{ value: "Unavailable" }],
            selectedTimezone: "Africa/Cairo",
            selected: null,
            isLoading: false,
            loadingTimeZones: false,
            durationsList: [
                {
                    id: 6,
                    name: this.$t("clientWizard.sixMonths"),
                },
                {
                    id: 12,
                    name: this.$t("clientWizard.twelveMonths"),
                },
                {
                    id: 24,
                    name: this.$t("clientWizard.twentyFourMonths"),
                },
                {
                    id: 36,
                    name: this.$t("clientWizard.thirtySixMonths"),
                },
            ],
        };
    },
    watch: {
        "$i18n.locale"() {
            this.durationsList = [
                {
                    id: 6,
                    name: this.$t("clientWizard.sixMonths"),
                },
                {
                    id: 12,
                    name: this.$t("clientWizard.twelveMonths"),
                },
                {
                    id: 24,
                    name: this.$t("clientWizard.twentyFourMonths"),
                },
                {
                    id: 36,
                    name: this.$t("clientWizard.thirtySixMonths"),
                },
            ];
        },
    },
    computed: {
        interviewSlots() {
            return this.options.map((option) => {
                if (this.$i18n.locale == "en") {
                    moment.locale("en");
                } else {
                    moment.locale("ar");
                }
                const isPast =
                    option.value !== "Unavailable" &&
                    moment(option.value).tz(this.selectedTimezone).isBefore(moment());

                    
                return {
                    id: option.id,
                    value:
                        option.value !== "Unavailable"
                            ? momentTimezone.tz(option.value, option.timezone)
                                .tz(this.selectedTimezone)
                                .format(
                                    `DD MMMM YYYY [${this.$i18n.locale == "en" ? "at" : "في"
                                    }] hh:mm A`
                                )
                            : option.value,
                    isPast: isPast,
                };
            });
        },
        timezones() {
            let timezones = momentTimezone.tz.names();
            timezones = timezones.map((timezone) => {
                return {
                    name: `${timezone.split("/")[1]} Time`,
                    value: timezone,
                };
            });
            return timezones;
        },
    },
    methods: {
        slotFormat(slot) {
            return moment.utc(slot).format("D MMM YYYY, h:mm A");
        },
        dataFormat(date) {
            return moment(date).format("D MMM");
        },
        meetingTime(time, timezone) {
            if (this.$i18n.locale == "en") {
                moment.locale("en");
                return `${moment(time).format("DD/MM/YYYY")} at ${moment(time).format(
                    "h:mm A"
                )} ${this.timezones.find((tz) => tz.value == timezone)?.name}`;
            } else {
                moment.locale("ar");
                return `${moment(time, timezone).format("YYYY/MM/DD")} فى ${moment(
                    time,
                    timezone
                ).format("h:mm A")} ${this.timezones.find((tz) => tz.value == timezone)?.name
                    }`;
            }
        },
        getInterviewSlots() {
            // get interview slots
             this.selectedTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
             
            this.options = [
                ...this.interview.interview_slots.map((slot) => ({
                    value: slot.start,
                    id: slot.id,
                    ...slot,
                })),
                ...this.options,
            ];

            console.log("this.options", this.options);
        },
        confirm() {
            if(this.isLoading) return;
            if (this.selected == null) {
                this.error = true;
                return;
            }
            this.error = false;
            this.isLoading = true;
            if (this.selected.value === "Unavailable") {
                this.axios
                    .patch(`/api/talent-selection/${this.$route.params.id}`, {
                        status: "canceled",
                        cancelation_reason:
                            "Client is unavailable at the talent proposed new slot",
                    })
                    .then(() => {
                        this.$emit("updated");
                    })
                    .catch((error) => {
                        console.log(error);
                    }).finally(() => {
                        this.isLoading = false;
                    });
            } else {
                this.axios
                    .patch(`/api/talent-selection/${this.$route.params.id}`, {
                        status: "scheduled",
                        slot_id: this.selected.id,
                    })
                    .then(() => {
                        this.$emit("updated");
                    })
                    .catch((error) => {
                        console.log(error);
                    }).finally(() => {
                        this.isLoading = false;
                    });
            }

        },
    },
};
</script>

<style lang="scss" scoped>
.form-check .form-check-input {
    margin-left: 0;
}

.form-check-input:focus {
    border-color: rgb(255 198 41 / 25%);
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(255 198 41 / 25%);
}

.form-check-input:checked {
    background-color: #fff;
    border-color: rgb(255 198 41);
}

.form-check-input:checked[type="radio"] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle fill='rgb(255 198 41)'  r='2.5' /%3e%3c/svg%3e");
}

.form-check-input {
    width: 1.5em;
    height: 1.5em;
}

.rtl .new-slot .form-check-input {
    margin-right: unset;
}

.rtl .new-slot .form-check-label {
    padding-right:0;
    padding-left: inherit;
}
</style>