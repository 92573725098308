<template>
  <div
    class="container pt-5 banner-wrapper align-items-center position-relative banner z-index-10"
    :class="{ 'z-index-20': showModal }"
  >
    <div class="tw-flex tw-justify-center tw-items-center lg:tw-my-12 tw-my-0">
      <div
        class="homepage-switcher toggler-wrapper lg:tw-max-w-[500px] tw-max-w-[266.5px] tw-mx-6 tw-rounded-full"
        :class="{ 'tw-bg-[#fff6]': !toggle, 'tw-bg-primary-400': toggle }"
      >
        <span
          class="lg:tw-text-3xl tw-text-xl tw-text-white tw-uppercase tw-pe-4 lg:tw-pe-8 tw-cursor-pointer"
          :class="{ 'hire-active lg:tw-text-[37px] lg:tw-leading-[67px]': !toggle }"
          @click="toggle = false"
        >
          {{ $t("home.hire") }}
        </span>
        <toggle-button
          v-model="toggle"
          :color="{ unchecked: '#F4F1F8', checked: '#503E90' }"
          :switch-color="{ unchecked: '#000', checked: '#fff' }"
          :value="toggle"
          :margin="windowWidth > 550 ? 8 : 6"
          :height="windowWidth > 550 ? 69 : 39"
          :width="windowWidth > 550 ? 133 : 72"
          :sync="true"
          :labels="false"
        />
        <span
          class="lg:tw-text-3xl tw-text-xl tw-ps-4 lg:tw-ps-8 tw-text-black tw-uppercase tw-cursor-pointer"
          :class="{ 'apply-active lg:tw-text-[37px] lg:tw-leading-[67px]': toggle }"
          @click="toggle = true"
        >
          {{ $t("home.apply") }}
        </span>
      </div>
    </div>
    <template v-if="talent">
      <div class="text-center talentsBanner align-items-center">
        <div class="tw-mt-[48px] lg:tw-mt-4">
          <h1 class="mb-4 text-white">
            <span
              class="tw-font-bold !tw-text-5xl lg:!tw-text-[75px] !tw-leading-[51px] !lg:tw-font-semibold tw-flex tw-flex-col lg:tw-flex-row tw-justify-center"
            >
              {{ $t("home.getYour") }}
              <span
                class="text-yellow tw-font-bold !tw-text-5xl lg:!tw-text-[75px] !tw-leading-[51px] !lg:tw-font-semibold lg:tw-mx-2 tw-mx-1"
              >
                {{ $t("home.dreamJob") }}
              </span>
              {{ $t("home.here") }}
            </span>
            <span
              class="banner-heading d-block tw-font-medium !tw-text-base lg:!tw-text-[27px] !tw-leading-[2rem] lg:!tw-leading-[37px]"
            >
              {{ $t("home.findYourDreamJobParagraph") }}
            </span>
          </h1>
          <button
            type="button"
            class="btn btn-primary btn-md tw-my-3 tw-mx-auto !tw-h-[42.88px] lg:!tw-h-auto !tw-flex !tw-items-center"
            @click="sendGetStartedTrackingEvent()"
          >
            {{ $t("home.getStarted") }}
          </button>
        </div>

        <div class="mt-4 banner-arrow position-relative">
          <img loading="lazy"
            src="../../../../assets/images/talents-white-shape.svg"
            height="260px"
            width="260px"
            class="img-fluid topIntroimg"
            data-aos="fade-left"
          />
        </div>
      </div>
    </template>
    <template v-else>
      <div
        class="text-center clients-banner row tw-flex tw-flex-col tw-items-center tw-justify-between text-lg-start"
      >
        <div class="tw-mt-[48px] lg:tw-mt-4">
          <h1 class="mb-4 text-white">
            <span
              class="tw-flex tw-flex-col lg:tw-flex-row tw-justify-center tw-gap-2"
            >
              <span
                class="lg:tw-flex tw-text-center tw-justify-center tw-text-primary tw-block tw-font-bold !tw-text-5xl lg:!tw-text-[75px] !tw-leading-[51px] !lg:tw-font-semibold"
              >
                {{ $t("home.hire") }}
              </span>
              <span
                class="tw-text-[#1e2022] tw-font-bold !tw-text-5xl lg:!tw-text-[75px] !tw-leading-[51px] !lg:tw-font-semibold"
              >
                {{ $t("home.topTalents") }} <br class="lg:tw-hidden tw-block" />
                {{ $t("home.topTalents1") }}
              </span>
            </span>
            <span
              class="tw-text-[#373a3b] lg:!tw-text-[35px] banner-heading d-block !tw-text-xl tw-font-normal lg:tw-font-semibold"
            >
              {{ $t("home.hireTopTalentsParagraph") }}
            </span>
          </h1>

          <div class="mt-4 tw-mb-0 lg:tw-mb-4 button-wrapper">
            <button
              type="button"
              class="btn btn-primary purple-btn btn-md !tw-h-[42.88px] lg:!tw-h-auto !tw-flex !tw-items-center"
              @click="featureId3 ? sendTrackingEvent($event, { type: 'client', route: '/client/wizard' }) : sendGetStartedTrackingEvent()"
            >
              {{ $t("home.getStarted") }}
            </button>
          </div>
        </div>
        <Projects float />
      </div>
    </template>
    <transition name="fade" appear>
      <div v-if="showModal" class="modal">
        <div class="modal-background" @click="showModal = false"></div>
        <div class="modal-content">
          <!-- Close button positioned at top right -->
          <div class="tw-py-4 text-end">
            <button class="btn-close" @click="showModal = false"></button>
          </div>
          <a class="tw-cursor-pointer" @click="sendTrackingEvent($event, { type: 'client', route: '/client/wizard' })">
            <h3>{{ $t("home.ImHiring") }}</h3>
            <p>{{ $t("home.ImHiringInfo") }}</p>
          </a>
          <a class="tw-cursor-pointer" @click="sendTrackingEvent($event, { type: 'talent', route: '/talent-signup' })">
            <h3>{{ $t("home.ImTalent") }}</h3>
            <p>{{ $t("home.ImTalentInfo") }}</p>
          </a>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>

const Projects = () => import(/* webpackChunkName: "projects" */  "../projects/projects.vue");

import { ToggleButton } from "vue-js-toggle-button";




export default {
  components: { Projects, ToggleButton },
  props: {
    talent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      toggle: true,
      showModal: false,
      windowWidth: window.innerWidth,
      featureId3: false,
    };
  },
  watch: {
    toggle(newValue) {
      this.$emit("toggle", newValue);
    },
  },
  methods: {
    getStarted() {
      this.showModal = true
     if (window.gtag)  window.gtag("event", "get started", { click_cta: 'Main Button' })
    },
    handleRedirection(path) {
      this.$router.push({ path });
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    sendTrackingEvent(event, data) {
      event.preventDefault();
     if (window.gtag)  window.gtag("event", "user type selected", { user_type: data.type })
     
      this.$router.push(data.route);
    },
    sendGetStartedTrackingEvent() {
      this.showModal = true;
     if (window.gtag)  window.gtag("event", "get started", { click_cta: 'Main Button' });
    },
    checkFeatureflag() {
      this.featureId3 =
        localStorage.getItem("CRO_feature_flag") ===
        "ID3_sitewide_rearranging_screens";
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);

  },
  created() {
    this.checkFeatureflag();
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
    const { type } = this.$route.params;
    if (["talent", "client"].includes(type)) {
      this.toggle = type == "client" ? false : true;
    } else {
      this.toggle = false;
    }
  },
};
</script>

<style lang="scss">
.vue-js-switch .v-switch-core .v-switch-button {
      top: -1px;
}
.z-index-10 {
  z-index: 10;
}
.z-index-20 {
  z-index: 10;
}

.toggler-wrapper {
  width: 100%;
  max-width: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 30px;
  @media (max-width: 768px) {
    max-width: 340px;
    padding: 5px 30px;
    height: 63px;
  }
}

div.landing_wrapper .nav-banner-wrapper::after {
  @media (max-width: 768px) {
    top: 31%;
    right: 0%;
    bottom: 0;
    width: 65%;
    height: 58%;
  }
}

.landing_wrapper .topIntro:has(.clients-banner)::after { 
    @media (max-width: 768px) {

    top: 43%;
    right: 0;
    bottom: 0;
    width: 25%;
    height: 40%;
    }
}

.landing_wrapper .topIntro:has(.clients-banner) {
  @media (max-width: 768px) {
    background-position-x: center;
  }
}

.landing_wrapper.landing_wrapper-client .topIntro::after  {
      @media (max-width: 768px) {

    top: 43%;
    right: 0;
    bottom: 0;
    width: 25%;
    height: 40%;
      }
}

.landing_wrapper.landing_wrapper-client .topIntro {
  @media (max-width: 768px) {
    background-position-x: center;
  }
}


.clients-banner {
  min-height: 480px;
}

.clients-banner {
  height: 100%;
  position: relative;
  @media (max-width: 768px) {
    min-height: 640px;
  }
}

.banner > div:not(.modal) {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  & > div h1,
  & > div span {
    text-align: center !important;
  }
  .button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .banner-heading {
    max-width: 900px;
    margin: 40px auto;
  }

  button {
    border-radius: 30px;
    padding-inline: 50px;
  }

  .banner-arrow {
    width: 350px;
    margin: 0 auto;
    padding-block: 80px 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media (max-width: 768px) {
      width: 130px;
      padding-block: 20px 0;
      position: absolute !important;
      bottom: -90%;
      right: 10%;
    }
  }
}

html[dir="rtl"] {
  .banner-arrow {
    justify-content: flex-start !important;
  }
}

@media (max-width: 768px) {
  .talentsBanner .banner-heading {
    font-size: 18px !important;
    line-height: 25px;
    font-weight: 300;
  }
  .talentsBanner .banner-arrow {
    padding-inline-end: 0px;
    width: 200px;
    margin-inline-start: auto;
  }
}
.vue-js-switch .v-switch-core {
  border: 1.57px solid rgba(215, 215, 215, 1);
}

.hire-active {
  font-weight: 700;
  line-height: 67px;
  background: linear-gradient(
    to bottom right,
    #120d26 0%,
    #422e87 25%,
    #775bbc 60%,
    #ffc629 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.apply-active {
  font-weight: 700;
  line-height: 67px;
  background: linear-gradient(to right, #ffc629 0%, #3bd4ae 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.homepage-switcher {
  direction: ltr;
}
</style>

<style lang="scss" scoped>
/* Define the fade-in transition */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

/* Modal styles */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

.modal a {
  width: calc(100% - 100px);
  min-width: 300px;
  margin-inline: auto;
  padding: 25px 20px;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0 15px 30px 0 #f6f6f6;
  transition: background-color 0.3s ease;
  border: 1px solid #e6e6e6;
  text-align: center;
}

.modal a:first-of-type {
  margin-bottom: 30px;
}

.modal h3 {
  color: #422e87;
  font-size: 28px;
  font-weight: 800;
  transition: color 0.3s ease;
}

.modal p {
  font-size: 23px;
  color: #494d55;
  transition: color 0.3s ease;
  line-height: auto !important;
}

@media (max-width: 768px) {
  .modal h3 {
    font-size: 18px;
  }

  .modal p {
    font-size: 14px !important;
  }
}

.modal a:hover {
  background-color: #422e87;
  border-color: transparent;
}
.modal a:hover h3 {
  color: #ffc629;
}
.modal a:hover p {
  color: #fff;
}

/* Modal background styles */
.modal-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7); /* Black background with opacity */
}

/* Modal content styles */
.modal-content {
  position: relative; /* Add relative positioning to the content container */
  background: white;
  padding: 10px 20px 30px !important;
  border-radius: 4px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: calc(100% - 40px);
  max-width: 700px;
  border-radius: 30px;
  overflow: hidden;
}
</style>
