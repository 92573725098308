<template>
  <div>
    <section
      :class="[
        'container sign-up mx-auto px-0 row d-flex flex-lg-row justify-content-center flex-nowrap flex-column-reverse align-items-center mt-3',
        className,
      ]"
    >
      <div class="px-0 one-step-sign-up-form col-lg-12">
        <form @submit.prevent="submit">
          <div class="row">
            <div class="mb-4 col-sm-6 col-12 wow fadeInUp animated">
              <label class="form-label required tw-text-gray-800">
                {{ $t("signup.firstName") }}
              </label>
              <input
                class="px-2 w-100"
                v-model.trim="$v.user.first_name.$model"
                :placeholder="$t('signup.firstName')"
              />
              <VulidateError
                class="error-label"
                :validationField="$v.user.first_name"
                :params="[
                  'required',
                  'alphaNumericWithSpecialChars',
                  { maxLength: { number: '50' } },
                ]"
              />
            </div>

            <div class="mb-4 col-sm-6 col-12 wow fadeInUp">
              <label class="form-label required tw-text-gray-800">
                {{ $t("signup.lastName") }}
              </label>
              <input
                class="px-2 w-100"
                v-model.trim="$v.user.last_name.$model"
                :placeholder="$t('signup.lastName')"
              />
              <VulidateError
                class="error-label"
                :validationField="$v.user.last_name"
                :params="[
                  'required',
                  'alphaNumericWithSpecialChars',
                  { maxLength: { number: '50' } },
                ]"
              />
            </div>
          </div>

          <div class="row">
            <div class="mb-2 col-sm-6 col-12 wow fadeInUp">
              <label class="form-label required tw-text-gray-800">
                {{ $t("signup.email") }}
              </label>
              <input
                class="px-2 w-100"
                v-model.trim="$v.user.email.$model"
                :disabled="isLoggedIn"
                :placeholder="$t('signup.email')"
              />
              <VulidateError
                :validationField="$v.user.email"
                :params="[]"
                class="error-label"
              />
            </div>

            <div class="mb-2 col-sm-6 col-12 wow fadeInUp mobile-phone">
              <label class="mb-1 tw-text-gray-800 form-label required">
                {{ $t("signUp.phone") }}
              </label>
              <div id="phone-number">
                <VuePhoneNumberInput
                  v-model="user.phone"
                  size="sm"
                  class="w-100"
                  ref="phoneInput"
                  :translations="{
                    countrySelectorLabel: $t('signup.countryCode'),
                    phoneNumberLabel: $t('signup.phoneNumber'),
                  }"
                  @update="updatePhoneNumber"
                  :default-country-code="defaultCountryCode"
                  error-color="#FF0000"
                  :no-example="true"
                />
                <span
                  class="error-label"
                  style="color: #ff6f59"
                  v-if="errorPhone && user.phone"
                  >{{ $t("validation.invalidphoneNumber") }}</span
                >
              </div>
            </div>
          </div>

          <div class="row">
            <div class="mb-4 col-sm-6 col-12">
              <label class="form-label required">{{
                $t("clientWizard.chooseIndustry")
              }}</label>
              <v-select
                v-model="user.project_type_id.$model"
                :reduce="(name) => name.id"
                class="style-chooser en_dropdown"
                :class="{ 'option-selected': user.project_type_id.$model }"
                :placeholder="$t('clientWizard.chooseIndustry')"
                :options="industryList"
                label="name"
              >
                <div slot="no-options">
                  {{ $t("talent.sorryNoMatchingOptions") }}
                </div>
              </v-select>
              <div class="error-select-validation-message">
                <VulidateError
                  :validationField="user.project_type_id"
                  :params="['required']"
                />
              </div>
            </div>

            <div class="mb-4 col-sm-6 col-12 wow fadeInUp">
              <label class="form-label tw-text-gray-800">
                {{ $t("signUp.website") }}
              </label>
              <input
                class="px-2 w-100"
                v-model.trim="$v.user.website.$model"
                :placeholder="$t('signUp.website')"
              />
              <VulidateError
                class="error-label"
                :validationField="$v.user.website"
                :params="['urlValidation', { maxLength: { number: '100' } }]"
              />
            </div>
          </div>

          <label class="form-label required tw-text-gray-800">
            {{ $t("signUp.chooseService") }}
          </label>
          <div
            class="tw-mt-4 tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-4 services"
          >
            <div
              v-for="service in services"
              :key="service.id"
              class="services__service"
            >
              <div
                :class="{ selected: selectedServices.includes(service.id) }"
                @click="toggleService(service.id)"
              >
                {{ service.name }}
                <img src="@/assets/images/signup-mark.svg" alt="! mark" />
              </div>
              <transition name="fade">
                <p
                  class="tw-mt-2 tw-mb-4 md:tw-mb-0 tw-px-1 tw-flex tw-items-start tw-gap-2 tw-text-xs tw-text-[#422E87]"
                >
                  <img
                    src="@/assets/images/signup-mark-colored.svg"
                    alt="! mark"
                  />
                  {{ service.tooltip }}
                </p>
              </transition>
            </div>
          </div>

          <div class="requestsInfo">
            <h3>
              {{ $t("signUp.addRequestsInfo") }}
            </h3>
            <div
              v-for="(request, index) in user.requests"
              :key="index"
              class="requestsInfo__request md:tw-mb-4 lg:tw-mb-4"
            >
              <div
                class="tw-flex tw-flex-col md:tw-grid md:tw-grid-cols-2 lg:tw-grid-cols-4 tw-gap-2"
              >
                <button
                  v-if="index !== 0"
                  class="tw-block md:tw-hidden tw-text-3xl tw-ms-auto tw-p-3"
                  type="button"
                  @click="removeRequest(index)"
                >
                  &times;
                </button>
                <div class="requestsInfo__input lg:tw-col-span-2">
                  <label class="form-label">{{ $t("clientWizard.chooseRole") }}</label>
                  <v-select
                    v-model="user.requests[index].role_id.$model"
                    :reduce="(name) => name.id"
                    class="style-chooser en_dropdown"
                    :class="{ 'option-selected': user.requests[index].role_id.$model }"
                    :placeholder="$t('clientWizard.chooseRole')"
                    :options="rolesList"
                    :selectable="(option) => !option.group"
                    @input="showUpLanguageFields(index)"
                    label="name"
                  >
                    <template #option="{ id, name, group, isCustom }">
                      <div v-if="group" class="requestsInfo__option tw-flex tw-gap-1 tw-items-center !tw-w-full" disabled>
                        {{ group }}
                      </div>
                      <div v-else-if="isCustom && name" class="requestsInfo__option--sticky tw-flex tw-gap-1 tw-items-center !tw-w-full tw-pt-2">
                        <img src="@/assets/images/register-information.svg" alt="Info icon" />
                        {{ name }}
                      </div>
                      <div v-else-if="id && name" class="requestsInfo__option !tw-w-full">
                        {{ name }}
                      </div>
                    </template>
                    <div slot="no-options">
                      {{ $t("talent.sorryNoMatchingOptions") }}
                    </div>
                  </v-select>
                  <div class="error-select-validation-message">
                    <VulidateError :validationField="user.requests[index].role_id" :params="['required']" />
                  </div>
                </div>
                <div>
                  <label class="form-label required">{{
                    $t("clientWizard.chooseAvailability")
                  }}</label>
                  <v-select
                    v-model="user.requests[index].availability.$model"
                    :reduce="(name) => name.id"
                    class="style-chooser"
                    :placeholder="$t('clientWizard.chooseAvailability')"
                    :options="availabilitiesList"
                    label="name"
                  >
                    <div slot="no-options">
                      {{ $t("talent.sorryNoMatchingOptions") }}
                    </div>
                  </v-select>
                  <div class="error-select-validation-message">
                    <VulidateError
                      :validationField="user.requests[index].availability"
                      :params="['required']"
                    />
                  </div>
                </div>
                <div>
                  <label class="form-label required">{{
                    $t("clientWizard.chooseDuration")
                  }}</label>
                  <v-select
                    v-model="user.requests[index].duration.$model"
                    :reduce="(name) => name.id"
                    class="style-chooser"
                    :placeholder="$t('clientWizard.chooseDuration')"
                    :options="durationsList"
                    label="name"
                  >
                    <div slot="no-options">
                      {{ $t("talent.sorryNoMatchingOptions") }}
                    </div>
                  </v-select>
                  <div class="error-select-validation-message">
                    <VulidateError
                      :validationField="user.requests[index].duration"
                      :params="['required']"
                    />
                  </div>
                </div>
                <div
                  class="tw-col-span-6 tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-2"
                >
                  <div
                    v-if="
                      user.requests[index].hasFirstLanguage &&
                      user.requests[index].firstLanguageCode == 'backend'
                    "
                  >
                    <label class="form-label required">{{
                      $t("clientWizard.choosePrimaryLanguage")
                    }}</label>
                    <v-select
                      v-model="user.requests[index].be_lang_id.$model"
                      :reduce="(name) => name.id"
                      class="style-chooser"
                      :placeholder="$t('clientWizard.choosePrimaryLanguage')"
                      :options="backendLanguages"
                      label="name"
                      @input="updateFirstLanguage(index, $event)"
                    >
                      <div slot="no-options">
                        {{ $t("talent.sorryNoMatchingOptions") }}
                      </div>
                    </v-select>
                    <div class="error-select-validation-message">
                      <VulidateError
                        :validationField="user.requests[index].be_lang_id"
                        :params="['required']"
                      />
                    </div>
                  </div>
                  <div
                    v-else-if="
                      user.requests[index].hasFirstLanguage &&
                      user.requests[index].firstLanguageCode == 'frontend'
                    "
                  >
                    <label class="form-label required">{{
                      $t("clientWizard.choosePrimaryLanguage")
                    }}</label>
                    <v-select
                      v-model="user.requests[index].fe_lang_id.$model"
                      :reduce="(name) => name.id"
                      class="style-chooser"
                      :placeholder="$t('clientWizard.choosePrimaryLanguage')"
                      :options="frontendLanguages"
                      label="name"
                      @input="updateFirstLanguage(index, $event)"
                    >
                      <div slot="no-options">
                        {{ $t("talent.sorryNoMatchingOptions") }}
                      </div>
                    </v-select>
                    <div class="error-select-validation-message">
                      <VulidateError
                        :validationField="user.requests[index].fe_lang_id"
                        :params="['required']"
                      />
                    </div>
                  </div>
                  <div v-if="user.requests[index].hasSecondLanguage">
                    <label class="form-label required">{{
                      $t("clientWizard.chooseSecondaryLanguage")
                    }}</label>
                    <v-select
                      v-model="user.requests[index].fe_lang_id.$model"
                      :reduce="(name) => name.id"
                      class="style-chooser"
                      :placeholder="$t('clientWizard.chooseSecondaryLanguage')"
                      :options="frontendLanguages"
                      label="name"
                      @input="updateSecondLanguage(index, $event)"
                    >
                      <div slot="no-options">
                        {{ $t("talent.sorryNoMatchingOptions") }}
                      </div>
                    </v-select>
                    <div class="error-select-validation-message">
                      <VulidateError
                        :validationField="user.requests[index].fe_lang_id"
                        :params="['required']"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <button
                v-if="index == 0"
                class="add tw-hidden md:tw-block"
                type="button"
                @click="addNewRequest"
              >
                <img src="@/assets/images/Ic_add-fill.svg" alt="Add request" />
              </button>
              <button
                v-else
                class="add tw-hidden md:tw-block"
                type="button"
                @click="removeRequest(index)"
              >
                <img
                  src="@/assets/images/signup-trash.svg"
                  alt="Remove request"
                />
              </button>
            </div>

            <button
              class="tw-mt-4 tw-border tw-flex tw-items-center tw-justify-center tw-w-full tw-gap-2 md:tw-hidden tw-py-2 tw-rounded-md"
              type="button"
              @click="addNewRequest"
            >
              <img src="@/assets/images/Ic_add-fill.svg" alt="Add request" />
              {{ $t("signUp.addRequest") }}
            </button>
          </div>

          <div class="mt-2 tw-text-center">
            <button
              type="submit"
              class="sign-up-btn"
              :class="{ 'tw-cursor-not-allowed': !validForm }"
              :disabled="!validForm"
            >
              {{ $t("signup.signUp") }}
            </button>
          </div>
        </form>
      </div>
    </section>

    <OTPModal
      v-show="isModalVisible"
      @close="isModalVisible = false"
      @handleOtpResend="handleOtpResend($event)"
      :email="user.email"
      :remainingOTP="remainingOTP"
      @closeSignup="closeSignUp"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { required, maxLength, email } from "vuelidate/lib/validators";
import { alphaNumericWithSpecialChars, urlValidation } from "../../utils/utils";
import VuePhoneNumberInput from "vue-phone-number-input";
import VulidateError from "../../components/shared/VulidateError/vulidateError.vue";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import "vue-select/dist/vue-select.css";
import OTPModal from "../../components/OTPModal/index.vue";
import store from "@/store";

export default {
  name: "CLientSignup",
  components: {
    VuePhoneNumberInput,
    VulidateError,
    OTPModal,
  },
  props: ["className", "closeModal", "isAlMostDone"],
  data: function () {
    return {
      isModalVisible: false,
      remainingOTP: null,
      defaultCountryCode: 'SA',
      errorPhone: "",
      industryList: [],
      rolesList: [],
      backendLanguages: [],
      frontendLanguages: [],
      availabilitiesList: [
        {
          id: 2,
          name: this.$t("clientWizard.fullTime"),
        },
        {
          id: 1,
          name: this.$t("clientWizard.partTime"),
        },
      ],
      durationsList: [
        {
          id: 6,
          name: this.$t("clientWizard.sixMonths"),
        },
        {
          id: 12,
          name: this.$t("clientWizard.twelveMonths"),
        },
        {
          id: 24,
          name: this.$t("clientWizard.twentyFourMonths"),
        },
        {
          id: 36,
          name: this.$t("clientWizard.thirtySixMonths"),
        },
      ],
      selectedServices: [],
      user: {
        first_name: "",
        last_name: "",
        email: "",
        phone: null,
        country_code: 'SA',
        website: null,
        project_type_id: {},
        requests: [
          {
            role_id: {},
            availability: {},
            duration: {},
            be_lang_id: { $model: null },
            fe_lang_id: { $model: null },
            hasFirstLanguage: false,
            hasSecondLanguage: false,
          },
        ],
      },
    };
  },
  validations: {
    user: {
      first_name: {
        required,
        maxLength: maxLength(50),
        alphaNumericWithSpecialChars,
      },
      last_name: {
        required,
        maxLength: maxLength(50),
        alphaNumericWithSpecialChars,
      },
      website: {
        maxLength: maxLength(100),
        urlValidation,
      },
      project_type_id: {
        required,
      },
      email: {
        required,
        email,
      },
      phone: {
        required,
      },
      requests: {
        $each: {
          availability: { required },
          duration: { required },
          be_lang_id: { required },
          fe_lang_id: { required },
        },
      },
    },
  },
  computed: {
    ...mapGetters({
      isLoggedIn: "auth/isLoggedIn",
    }),
    validForm: function () {
      const requestsValid = this.user.requests.every((request) => {
        const id = request.role_id.$model;
        if (id === 1) {
          return request.be_lang_id.$model !== null;
        } else if (id === 2) {
          return request.fe_lang_id.$model !== null;
        } else if (id === 3) {
          return (
            request.be_lang_id.$model !== null &&
            request.fe_lang_id.$model !== null
          );
        }
        return true;
      });

      return (
        this.selectedServices.length &&
        this.requestsAreValid &&
        (this.user.phone ? !this.errorPhone : true) &&
        !this.$v.$invalid &&
        requestsValid
      );
    },
    services() {
      const services = this.$t("signUp.services");
      return [
        {
          id: 1,
          name: services.teamAsAService,
          tooltip: this.$t("clientWizard.teamAsAServiceTooltip"),
        },
        {
          id: 2,
          name: services.productDesign,
          tooltip: this.$t("clientWizard.productDesignTooltip"),
        },
        {
          id: 3,
          name: services.technicalConsultation,
          tooltip: this.$t("clientWizard.techConsultantTooltip"),
        },
        {
          id: 4,
          name: services.payrollManagement,
          tooltip: this.$t("clientWizard.payrollManagementTooltip"),
        },
      ];
    },
    requestsAreValid() {
      let isValid = true;
      this.user.requests.forEach((request) => {
        if (isValid)
          isValid = !!request.availability.$model && !!request.duration.$model;
        else return;
      });

      return isValid;
    },
  },
  watch: {
    "$i18n.locale"() {

      const custom = this.rolesList.find((role) => role.id === 'custom')

      custom.name = this.$i18n.locale == "en" ? "Not sure (Need advice)" : "مو متأكد، أحتاج إلى استشارة";


      this.selectedServices = [];

      this.user.requests = [
        {
          role_id: {},
          availability: {},
          duration: {},
          be_lang_id: { $model: null },
          fe_lang_id: { $model: null },
          hasFirstLanguage: false,
          hasSecondLanguage: false,
        },
      ];

      this.availabilitiesList = [
        {
          id: 2,
          name: this.$t("clientWizard.fullTime"),
        },
        {
          id: 1,
          name: this.$t("clientWizard.partTime"),
        },
      ];
      this.durationsList = [
        {
          id: 6,
          name: this.$t("clientWizard.sixMonths"),
        },
        {
          id: 12,
          name: this.$t("clientWizard.twelveMonths"),
        },
        {
          id: 24,
          name: this.$t("clientWizard.twentyFourMonths"),
        },
        {
          id: 36,
          name: this.$t("clientWizard.thirtySixMonths"),
        },
      ];
    },
  },
  async mounted() {
    await this.getProjectData();
    await this.getIndustryList();
    await this.getRolesList();
    await this.getBackendLanguages();
    await this.getFrontendLanguages();
  },
  methods: {
    getProjectData() {

      if (this.isLoggedIn) {
        this.axios
          .get("/api/client/projects")
          .then((res) => {
            const data = res.data.data;

            this.defaultCountryCode = data.client?.phone_parsed?.country_alpha2;

            this.user = {
              first_name: data.client.first_name,
              last_name: data.client.last_name,
              email: data.client.email,
              country_code: data.client?.phone_parsed?.country_alpha2,
              phone: data.client.phone,
              website: data.website,
              project_type_id: data.project_type,
              requests: [
                    {
                      role_id: {},
                      availability: {},
                      duration: {},
                      be_lang_id: { $model: null },
                      fe_lang_id: { $model: null },
                      hasFirstLanguage: false,
                      hasSecondLanguage: false,
                    },
                  ],
            };

            this.$set(this.user.project_type_id, "$model", data.project_type.id);

            this.selectedServices = data.services.map((service) => service.service);

              data.signup_requests.map((request, index) => {               
                const id = request.role == null ? 'custom' : request.role.id; 
                if (this.user.requests[index]?.role_id) this.$set(this.user.requests[index]?.role_id, "$model", id);
                else {
                  this.addNewRequest()
                      this.$set(this.user.requests[index]?.role_id, "$model", id);
                    }

                    this.$set(this.user.requests[index].availability, "$model", request.availability.id);
                    this.$set(this.user.requests[index].duration, "$model",  request.duration);

                    this.$set(this.user.requests[index].be_lang_id, "$model", request.be_lang?.id);
                    this.$set(this.user.requests[index].fe_lang_id, "$model", request.fe_lang?.id);

                    if (id === 1) {
                      this.$set(this.user.requests[index], "hasFirstLanguage", !!request.be_lang);
                      this.$set(this.user.requests[index], "hasSecondLanguage", !!request.fe_lang);
                      this.$set(this.user.requests[index], "firstLanguageCode", "backend");
                    } else if (id === 2) {
                      this.$set(this.user.requests[index], "hasFirstLanguage", !!request.be_lang);
                      this.$set(this.user.requests[index], "hasSecondLanguage", !!request.fe_lang);
                      this.$set(this.user.requests[index], "firstLanguageCode", "frontend");
                    } else if (id === 3) {
                      this.$set(this.user.requests[index], "firstLanguageCode", "backend");
                      this.$set(this.user.requests[index], "hasFirstLanguage", !!request.be_lang);
                      this.$set(this.user.requests[index], "hasSecondLanguage", !!request.fe_lang);
                    } else {
                      this.$set(this.user.requests[index], "hasFirstLanguage", !!request.be_lang);
                      this.$set(this.user.requests[index], "hasSecondLanguage", !!request.fe_lang);
                    }
            }); 
          })
          .catch((err) => {
            console.error(err);
          });
      }
    },
    handleOtpResend($event) {
      this.remainingOTP = $event;
    },
    updatePhoneNumber(data) {
      this.user.country_code = data.countryCallingCode
        ? "+".concat(data.countryCallingCode)
        : null;
      this.errorPhone = !data.isValid;
    },
    addNewRequest() {
      this.user.requests.push({
        role_id: {},
        availability: {},
        duration: {},
        be_lang_id: { $model: null },
        fe_lang_id: { $model: null },
      });
    },
    showUpLanguageFields(index) {
      const id = this.user.requests[index].role_id.$model;
      this.$set(this.user.requests[index].be_lang_id, "$model", null);
      this.$set(this.user.requests[index].fe_lang_id, "$model", null);

      if (id === 1) {
        this.$set(this.user.requests[index], "hasFirstLanguage", true);
        this.$set(this.user.requests[index], "hasSecondLanguage", false);
        this.$set(this.user.requests[index], "firstLanguageCode", "backend");
      } else if (id === 2) {
        this.$set(this.user.requests[index], "hasFirstLanguage", true);
        this.$set(this.user.requests[index], "hasSecondLanguage", false);
        this.$set(this.user.requests[index], "firstLanguageCode", "frontend");
      } else if (id === 3) {
        this.$set(this.user.requests[index], "firstLanguageCode", "backend");
        this.$set(this.user.requests[index], "hasFirstLanguage", true);
        this.$set(this.user.requests[index], "hasSecondLanguage", true);
      } else {
        this.$set(this.user.requests[index], "hasFirstLanguage", false);
        this.$set(this.user.requests[index], "hasSecondLanguage", false);
      }
    },
    updateFirstLanguage(index, value) {
      if (this.user.requests[index].firstLanguageCode === "backend") {
        this.$set(this.user.requests[index].be_lang_id, "$model", value);
      } else {
        this.$set(this.user.requests[index].fe_lang_id, "$model", value);
      }
    },
    updateSecondLanguage(index, value) {
      this.$set(this.user.requests[index].fe_lang_id, "$model", value);
    },
    removeRequest(index) {
      this.user.requests.splice(index, 1);
    },
    async getIndustryList() {
      try {
        await this.axios.get("/api/staticContent/projectTypes").then((res) => {
          this.industryList = res.data.data;
        });
      } catch (error) {
        console.error(error);
      }
    },
    async getRolesList() {
      try {
        await this.axios.get("/api/staticContent/roles").then((res) => {
          this.rolesList = res.data;
          this.rolesList.push({
            id: 'custom',
            name:  this.$i18n.locale == "en" ? "Not sure (Need advice)" : "مو متأكد، أحتاج إلى استشارة",
            isCustom: true,
          })
        });
      } catch (error) {
        console.error(error);
      }
    },
    async getBackendLanguages() {
      try {
        await this.axios
          .get("/api/staticContent/backendLanguages")
          .then((res) => {
            this.backendLanguages = res.data.data;
          });
      } catch (error) {
        console.error(error);
      }
    },
    async getFrontendLanguages() {
      try {
        await this.axios
          .get("/api/staticContent/frontendLanguages")
          .then((res) => {
            this.frontendLanguages = res.data.data;
          });
      } catch (error) {
        console.error(error);
      }
    },
    toggleService(serviceId) {
      if (this.selectedServices.includes(serviceId)) {
        this.selectedServices = this.selectedServices.filter(
          (item) => item !== serviceId
        );
      } else {
        this.selectedServices.push(serviceId);
      }
    },
    async submit() {
      if (!this.validForm) {
        return;
      }
      let loader = this.$loading.show({});
      const project_type_id = this.user.project_type_id.$model;
      const requests = this.user.requests.map((request) => {
        return {
          role_id:
            request.role_id.$model == "custom" ? null : request.role_id.$model,
          availability: request.availability.$model,
          duration: request.duration.$model,
          be_lang_id: request.be_lang_id?.$model,
          fe_lang_id: request.fe_lang_id?.$model,
        };
      });

      const services = this.selectedServices;

      this.$store
        .dispatch("auth/oneStepRegister", {
          ...this.user,
          project_type_id,
          requests,
          services,
          signupSource: store.getters["shared/getUTMParameters"],
          marketing_campaign: this.$route.query.Campaign,
        })
        .then((res) => {
          this.remainingOTP = res.otp;
          this.user.user_id = res.id;
          if (window.gtag) window.gtag("event", "client one step wizard sign-up submitted", this.user);
          //if (window.gtag) window.gtag("event", "client sign-up submitted", this.user);


          
          localStorage.setItem("is_one_step", true);
          localStorage.setItem("meeting_reserved", true);
          if (this.isLoggedIn) {
           this.$router.push({ path: "/client/wizard" });

          }
          else {
            this.isModalVisible = true;
          }
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.status == 422 &&
            err.response.data.errors[0] &&
            err.response.data.errors[0].type == "otp"
          ) {
            localStorage.setItem("meeting_reserved", true);
            this.remainingOTP = 0;
            this.isModalVisible = true;
          } else {
            throw err;
          }
        })
        .finally(() => {
          loader.hide();
        });
    },
    closeSignUp() {
      this.$emit("closeSignup");
    },
  },
};
</script>

<style scoped src="./signup.css"></style>
<style lang="scss">
    .sign-up .one-step-sign-up-form input::disabled {
      border: 1px solid #9f9f9f;
      background-color: light-dark(rgb(229 229 229), rgba(59, 59, 59, 0.3));
    }

    .country-selector__list.slide-enter,
    .country-selector__list.slide-leave-to /* .fade-leave-active below version 2.1.8 */ {
      transition-duration: 0s !important;
      display: none;
      opacity: 0;
    }

    .country-selector__list.slide-enter-active,
    .country-selector__list.slide-leave-active {
      transition-duration: 0s !important;
      display: none;
      opacity: 0;
    }


.one-step-sign-up-form .v-select > div:first-child {
  padding: 4px 0 4px !important;
  border-radius: 4px !important;
  min-height: 39px;
}

.one-step-sign-up-form .v-select input::placeholder {
  font-size: 14px;
}

.one-step-sign-up-form .vs__selected-options {
  .vs__selected + .vs__search {
    height: 0 !important;
    margin: 0;
    border: 0;
  }
}

.one-step-sign-up-form .vs__search {
  margin: 0;
}

.en_dropdown .vs__dropdown-option {
  text-align: left;
  min-width: 280px!important;
  margin-inline-end: auto;
}

.one-step-sign-up-form .vs__dropdown-option .requestsInfo__option {
  display: flex;
  width: 100%;
  color: #fff !important;
}

.services {
  &__service div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    border: 1px solid #e6e7e8;
    color: #6a6a6a;
    font-size: 12px;
    border-radius: 4px;
    padding: 8px;
    margin-right: 8px;
    margin-bottom: 8px;
    cursor: pointer;
    user-select: none;

    &.selected {
      font-weight: 600;
      border: 1px solid #422e87;
      color: #292929;
    }
  }
}

.requestsInfo {
  margin-top: 3rem;
  h3 {
    font-size: 24px;
    font-weight: 600;
    color: #292929;
    margin-bottom: 10px;
    background-color: #f1edff;
    display: inline-block;
  }

  .vs__dropdown-menu {
    .vs__dropdown-option * {
      color: #292929 !important;
    }
    
    .vs__dropdown-option:not(.vs__dropdown-option--disabled):not(:has(.requestsInfo__option--sticky)):hover * {
      color: #fff !important;
    }

    .vs__dropdown-option--highlight .requestsInfo__option {
      width: 100% !important;
      color: #fff !important;
    }

    .vs__dropdown-option--selected {
      background-color: #73a6f2;
      color: #fff !important;
    }

    .vs__dropdown-option--selected * {
      color: #fff !important;
    }
  }

  &__request {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    padding-bottom: 16px;
    border-bottom: 1px solid #d9d9d9;

    & > div {
      flex: 1;
    }
    .add {
      background-color: #fff;
      border: 1px solid #422e8726;
      border-radius: 7px;
      padding: 8px;
      cursor: pointer;
      margin-top: 24px;
      img {
        width: 20px;
        height: 20px;
      }
    }
  }

  &__input {
    width: 100%;
  }
}

.v-select {
  direction: ltr!important;
}

html[dir="rtl"] .v-select input {
  text-align: end!important;
}

.option-selected .vs__selected-options {
  max-width: calc(100% - 54px);

  .vs__selected {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

.en_dropdown .vs__dropdown-option--disabled {
  background-color: #e7e7e7;
  color: #292929;
  &:hover {
    background-color: #e7e7e7;
    color: #292929;
  }
}
.vs__dropdown-option {
  position: relative;
}

.vs__dropdown-menu :has(.requestsInfo__option--sticky) {
  position: sticky;
  bottom: -2px;
  background-color: white;
  z-index: 1;
  border-top: 1px solid #ccc;
}
</style>
