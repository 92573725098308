<template>
  <div>
    <ClientWizardContainer :step="stepNum" :hasError="validForm()" ref="ClientWizardContainer">
      <div
        class="container position-relative z-index-2"
        :class="{ 'tw-pt-8': onStepWizard }"
        id="stepper-items"
        slot="form"
      >
        <h3 class="my-1 text-center font-weight-bold main-step-title">
          {{ $t("clientWizard.bookAMeeting") }}
        </h3>
        <p class="text-center gray-color-text">
          {{ $t("clientWizard.selectADateTime") }}
        </p>
        <VueCalendly
          v-if="isLoadingCalendly"
          :url="`${
            calendars.link + '?hide_event_type_details=1&hide_gdpr_banner=1'
          }`"
          :class="{
            'tw-mt-3 adaptive-height': !onStepWizard,
            'onestepwizard-height': onStepWizard,
          }"
        ></VueCalendly>
      </div>
    </ClientWizardContainer>
  </div>
</template>
<script>
import VueCalendly from "vue-calendly";

import { mapGetters } from "vuex";

// import axios from "axios";
import ClientWizardContainer from "../ClientWizzardContainer/index1.vue";

export default {
  name: "Booking",
  data: function () {
    return {
      date: true,
      url:
        process.env.VUE_APP_CALENDLY_URL ||
        "https://calendly.com/ahmed-shibob/30min?hide_event_type_details=1&hide_gdpr_banner=1", // squadio
      calendars: null,
      isLoadingCalendly: false,
    };
  },
  components: {
    ClientWizardContainer,
    VueCalendly: VueCalendly.VueCalendly,
  },
  props: ["stepNum"],
  computed: {
    ...mapGetters({
      user: "auth/getUser",
    }),
    onStepWizard() {
     return !!localStorage.getItem("is_one_step");
    },
  },
  mounted: function () {
    this.getUserData();
    window.addEventListener("message", this.bookMeeting);
    this.$store.dispatch("auth/getClientProjectsData");
  },
  destroyed: function () {
    window.removeEventListener("message", this.bookMeeting);
  },
   metaInfo() {
    return {
    title: `Client Registration - Step 5: Schedule Your Meeting`,
    };
  },
  methods: {
    gtag_report_conversion() {
      // eslint-disable-next-line no-undef
      if (!gtag) return false;
      // eslint-disable-next-line no-undef
      if (window.gtag) window.gtag("event", "conversion", {
        send_to: "AW-11010562907/2gHFCM7turYYENu2n4Ip",
      });
      return false;
    },
    validForm() {
      return this.date;
    },
    getUserData() {
      this.axios
        .get("/api/calendars?meeting_type=client_onboarding&is_default=1")
        .then((res) => {
          this.isLoadingCalendly = true;
          this.calendars = res.data.data[0];
        })
        .catch((err) => {
          console.log(err);
        });
    },
    logOut() {
       this.axios.get("/api/users/me").then((res) => {
         const user = res.data.data
        
          if (window.gtag) window.gtag("event", "client booked meeting", {
          user_type: "Client",
          step_number: 7,
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email,
        });
        this.$store.dispatch("auth/logOut").then(() => {
        
        this.$nextTick(() => {
            this.$router.push({ path: "/login" });
        });
        // window.location.reload();
      });
      });
    
    },
    bookMeeting(e) {
      if (e.data.event == "calendly.event_scheduled") {
        if (e?.data?.payload?.event?.uri) {
          const uriArr = e.data.payload.event.uri.split("/");
          const uuid = uriArr[uriArr.length - 1];
          this.$store
            .dispatch("auth/postBookMeetingRequest", {
              calendar_id: this.calendars.id,
              meeting_id: uuid,
            })
            .then(() => {
              this.logOut()
              this.gtag_report_conversion();
            });
          //  this.$store.dispatch("auth/setStep", 6);
        }
      }
      //[FixMe] business requirements needs multi syncrounes actions
      // {
      //   if (e.data.event == "calendly.event_scheduled") {
      //     try {
      //       axios
      //         .get(e.data.payload.event.uri, {
      //           headers: { Authorization: process.env.VUE_APP_CALENDLY_TOKEN },
      //         })
      //         .then((res) => {
      //           if (
      //             res.data.resource.end_time &&
      //             res.data.resource.start_time &&
      //             res.data.resource.location.join_url&&
      //             res.data.resource.event_memberships
      //           ) {
      //             //getting invitee UUID from URI
      //             const uuid = res.data.resource.uri.split("scheduled_events/");
      //             //getting mobile number
      //             try {
      //               axios
      //                 .get(`https://api.calendly.com/scheduled_events/${uuid[1]}/invitees`, {
      //                   headers: {
      //                     Authorization: process.env.VUE_APP_CALENDLY_TOKEN,
      //                   },
      //                 })
      //                 .then((resp) => {

      //                      //getting calendly organizer email
      //             try {
      //               axios
      //                 .get(res.data.resource.event_memberships[0].user, {
      //                   headers: {
      //                     Authorization: process.env.VUE_APP_CALENDLY_TOKEN,
      //                   },
      //                 })
      //                 .then((response) => {
      //                   const phoneCollection=[]
      //                   resp.data.collection[0].questions_and_answers.map(collection=>collection.question.includes("Mobile Number")?phoneCollection.push(collection):null)
      //                   const country_code=(phoneCollection[0].answer).split(" ")[0]
      //                   const noSpacesPhone= `+${(phoneCollection[0].answer).replace(/[^\d]/g, '')}`;
      //                   const phone=noSpacesPhone.replace(country_code,'');
      //                   try {
      //                     this.axios.post(
      //                       "/api/client/projects/bookMeeting",
      //                       {
      //                         meeting_start_time: res.data.resource.start_time,
      //                         meeting_end_time: res.data.resource.end_time,
      //                         meeting_link: res.data.resource.location.join_url,
      //                         sendTo: response.data.resource.email,
      //                         country_code,
      //                         phone

      //                       },
      //                       {
      //                         headers: {
      //                           Authorization:
      //                             "Bearer " + this.$store.state.auth.token,
      //                         },
      //                       }
      //                     );
      //                     localStorage.setItem("isBooked", true);
      //                     this.date = false;
      //                     this.$store.dispatch("auth/setStep", ++this.stepNum);
      //                     this.$store.commit(
      //                       "client/set_booking_date",
      //                       res.data.resource.start_time
      //                     );
      //                   } catch (error) {
      //                     console.log(error);
      //                   }
      //                 });
      //             } catch (err) {
      //               console.log(err);
      //             }
      //                 });
      //             } catch (err) {
      //               console.log(err);
      //             }

      //           }
      //         });
      //     } catch (error) {
      //       console.log(error);
      //     }
      //   }
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.spinner {
  display: none !important;
  visibility: hidden !important;
}

.adaptive-height {
  height: 600px !important;

  @media (max-width: 768px) {
    height: 800px !important;
  }
}
.onestepwizard-height {
  height: 700px !important;

  @media (max-width: 768px) {
    height: 800px !important;
  }
}
</style>
