<template>
  <div class="client-wizard max-900 page-ath-content">
    <div :class="className">
      <div>
        <div class="bg-white">
          <div class="container">
            <header class="client-header">
              <nav class="navbar navbar-expand-xl landing-nav ps-2">
                <router-link to="/" class="navbar-brand">
                  <img
                    loading="lazy"
                    src="../../assets/images/colored_logo.svg"
                    alt=""
                    width="120"
                  />
                </router-link>

                <div
                  class="btn-group loggedin-dropdown ms-auto"
                  style="order: 2"
                >
                  <div
                    class="line-hight-28 d-flex align-items-center"
                    @click="previous()"
                    v-if="step > 1"
                  >
                    <img
                      loading="lazy"
                      src="../../assets/images/icons/arrow-back.svg"
                      class="me-3 btn-back-img"
                    />
                    <button class="p-0 border-0 btn btn-back" type="button">
                      {{ $t("clientWizard.back") }}
                    </button>
                  </div>
                  <LanguageSwitcher class="language-switcher-large" black />

                  <client-dropdown-menu
                    v-if="isLoggedIn"
                    :client-name="user.first_name"
                    :projects="projects"
                    :active-project="activeProject"
                    :has-nomination="isClientHasNomination"
                    :isClientHasContractRenewal="isClientHasContractRenewal"
                    not-logged-in
                    uncompleted-lead
                    @project-changed="changeCurrentProject($event)"
                  />
                </div>

                <div
                  v-if="step > 1"
                  class="hide-lang-lg line-hight-28 d-flex align-items-center ms-auto"
                  @click="previous()"
                >
                  <img
                    loading="lazy"
                    src="../../assets/images/icons/arrow-back.svg"
                    class="me-3 btn-back-img"
                  />
                  <button class="p-0 border-0 btn btn-back" type="button">
                    {{ $t("clientWizard.back") }}
                  </button>
                </div>

                <LanguageSwitcher
                  separator-dark
                  has-divider
                  divider-at-start
                  class="me-2 ms-auto tw-flex lg:tw-hidden"
                  :class="{ 'ms-auto': step == 1 }"
                />
                <button
                  class="navbar-toggler"
                  :class="{ 'ms-auto': onStepWizard }"
                  type="button"
                  @click="triggerMobileMenu = !triggerMobileMenu"
                >
                  <svg
                    data-v-0315f6de=""
                    width="20px"
                    height="23px"
                    viewBox="0 0 20 16"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                  >
                    <g
                      data-v-0315f6de=""
                      id="Client"
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <g
                        data-v-0315f6de=""
                        id="02.01.squadio2-final-design"
                        transform="translate(-1462.000000, -39.000000)"
                      >
                        <g
                          data-v-0315f6de=""
                          id="Group-5"
                          transform="translate(1462.000000, 39.000000)"
                        >
                          <rect
                            data-v-0315f6de=""
                            id="Rectangle"
                            x="0"
                            y="0"
                            width="20"
                            height="2"
                            rx="1"
                          ></rect>
                          <rect
                            data-v-0315f6de=""
                            id="Rectangle"
                            x="0"
                            y="8"
                            width="20"
                            height="2"
                            rx="1"
                          ></rect>
                          <rect
                            data-v-0315f6de=""
                            id="Rectangle"
                            x="0"
                            y="16"
                            width="20"
                            height="2"
                            rx="1"
                          ></rect>
                        </g>
                      </g>
                    </g>
                  </svg>
                </button>

                <div
                  class="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <ul
                    class="mb-2 nav col-12 col-lg-auto me-lg-auto justify-content-center mb-md-0"
                  >
                    <li>
                      <router-link to="/" class="px-3 nav-link">
                        {{ $t("home.home") }}
                      </router-link>
                    </li>
                  </ul>
                </div>

                <mobile-menu
                  :is-open="triggerMobileMenu"
                  client-wizard
                  @close-menu="menuClosed"
                  :projects="projects"
                  :active-project="activeProject"
                  @changeCurrentProject="changeCurrentProject"
                >
                  <li class="mobileMenu__item">
                    <router-link to="/">
                      {{ $t("home.home") }}
                    </router-link>
                  </li>
                  <li v-if="isClientHasNomination" class="mobileMenu__item">
                    <router-link to="/sq/client/nomination">
                      {{ $t("matchedTalents.nominations") }}
                    </router-link>
                  </li>
                  <li
                    v-if="isClientHasContractRenewal"
                    class="mobileMenu__item"
                  >
                    <router-link
                      class="px-3 nav-link"
                      :class="
                        $route.name == 'contractsRenewal'
                          ? 'link-secondary navbar-secondary'
                          : 'nav-link'
                      "
                      to="/sq/client/contracts-renewal"
                    >
                      {{ $t("matchedTalents.squadRenewal") }}
                    </router-link>
                  </li>
                  <li class="mobileMenu__item" v-if="user.id">
                    <button @click="logOut">
                      {{ $t("home.logout") }}
                    </button>
                  </li>
                  <li class="mobileMenu__item" v-else>
                    <router-link to="/login">
                      {{ $t("home.login") }}
                    </router-link>
                  </li>
                </mobile-menu>
              </nav>
            </header>
          </div>
        </div>
        <div class="text-center big-alert" v-if="step == 1">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <h5 class="text-white">
                  {{ $t("clientWizard.thisPhaseWillOnlyTake23Minutes") }}
                </h5>
                <p class="text-white">
                  {{
                    $t(
                      "clientWizard.pleaseHelpUsSpecifyYourBusinessNeedsByFulfillingTheseQuestions"
                    )
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="wizard wizard-padding-wrapper">
        <div v-if="!onStepWizard" class="text-center stepper-step">
          <div>
            <span class="progress-span"
              >{{ $t("clientWizard.step") }} {{ currentStep }} / 5</span
            >
          </div>
        </div>
        <div v-if="!onStepWizard" class="d-flex justify-content-center">
          <div class="progress-line">
            <linearProgressBar :value="this.step * 20" />
          </div>
        </div>
        <div class="formBox">
          <div class="form-input-steps">
            <div class="pt-0 tab-pane">
              <slot name="form"></slot>
            </div>
          </div>
        </div>
        <div class="text-center col-12 book-a-meeting" v-if="this.step != 5">
          <span>
            {{ $t("clientWizard.dontHaveTimeToFillAllTheDataBookAMeetingNow") }}
            &nbsp;</span
          >
          <br />

          <span
            class="cursor-pointer secondry-color book-meeting-decoration"
            @click="bookMeetingFunc"
          >
            {{ $t("clientWizard.bookMeetingNow") }}
          </span>
        </div>
        <div
          class="text-center bg-white next-step next-step-box tw-flex tw-items-center tw-justify-start !tw-ml-0"
          style="z-index: 2"
        >
          <span class="!tw-text-[9px]" v-if="this.step < 5">
            {{ $t("clientWizard.nextStep") }} :</span
          >

          <button
            v-if="this.step < 5"
            :class="{
              '!tw-text-[9px] !tw-mx-3 !tw-px-0 !tw-py-4 ':
                $i18n.locale == 'en',
              '!tw-text-[14px] tw-w-[12rem]': $i18n.locale == 'ar',
              'tw-mx-auto': $i18n.locale == 'ar' && step == 1,
              'tw-w-[12rem]': $i18n.locale == 'en' && step == 1,
              'tw-w-[14rem]': $i18n.locale == 'en' && step != 1,
            }"
            class="my-0 btn secondry-btn btnSecondary"
            type="button"
            @click.prevent="submit()"
            :disabled="hasError && isLoading"
          >
            {{ $t(`clientWizard.${BtnTxts[this.step - 1]}`) }}
          </button>

          <a
            @click="logOutToTalent"
            v-if="step == 1"
            style="color: #0d6efd"
            class="!tw-text-[9px]"
          >
            {{ $t("signUp.areYouTalent") }}
          </a>
        </div>
      </div>
      <SignupModal v-if="isOpen" :isOpen="isOpen" @update="isOpen = $event" />
    </div>
  </div>
</template>

<script>
import LanguageSwitcher from "@/components/shared/languageSwitcher/languageSwitcher.vue";
import MobileMenu from "@/components/MobileMenu";
import { mapActions, mapGetters } from "vuex";
import linearProgressBar from "../LinearProgressBar/index.vue";
import SignupModal from "./signupModal.vue";
import "./style.scss";
import "./wizard-client.css";
import ClientDropdownMenu from "@/components/ClientDropdownMenu";
import ClientSteps from "@/enums/ClientWizzardSteps.js";

export default {
  name: "ClientWizardContainer",
  components: {
    linearProgressBar,
    SignupModal,
    LanguageSwitcher,
    MobileMenu,
    ClientDropdownMenu,
  },
  props: [
    "className",
    "step",
    "hasError",
    "formData",
    "othersTechnologiesList",
    "technologiesList",
  ],
  data() {
    return {
      stepPercentage: this.currentStep * 20,
      currentStep: this.step,
      triggerMobileMenu: false,
      isOpen: false,
      isLoading: false,
      projects: [],
      featureId3: false,
    };
  },
  computed: {
    ...mapGetters({
      isLoggedIn: "auth/isLoggedIn",
      user: "auth/getUser",
      activeProject: "client/getActiveProject",
      isClientHasNomination: "auth/getClientNominationState",
      isClientHasContractRenewal: "auth/getClientHasContractRenewal",
    }),
    onStepWizard() {
      return !!localStorage.getItem("is_one_step");
    },
    stepsApis() {
      return [
        "/api/client/projects/services",
        "/api/client/projects/technologies",
        "/api/client/projects/details",
        "/api/client/projects/about",
        // "/api/client/projects/bookMeeting",
      ];
    },
    BtnTxts() {
      return [
        "yourProductsTechnologies",
        "readyToHire",
        "details",
        "bookAMeeting",
      ];
    },
  },
  mounted() {
    if (this.isLoggedIn && this.user) {
      this.getClientProjects();
    } else {
      this.registerPendingClient()
        .then(() => {
          this.getClientProjects();
        })
        .catch((err) => {
          console.error(err);
        });
    }

    const body = document.querySelector("body");
    body.classList.remove("is-fixed");
    this.userName = JSON.parse(localStorage.getItem("user"))?.first_name;
    if (this.userName) {
      this.setClientCounters();
    }
  },
  methods: {
    ...mapActions({
      handleSetActiveProject: "client/handleSetActiveProject",
      setClientCounters: "auth/setClientCounters",
      getUTMParameters: "shared/getUTMParameters",
      registerPendingClient: "auth/registerPendingClient",
    }),
    getClientProjects() {
      this.axios
        .get(`/api/clients/projects/minimal`)
        .then((result) => {
          this.projects = result.data.data;
          let payload = {
            projects: this.projects,
            project: result.data.data[0],
          };
          this.handleSetActiveProject(payload);
          // if (!this.activeProject && result.data.data[0]) this.handleSetActiveProject(payload)
        })
        .catch((err) => {
          console.log(err);
        });
    },
    changeCurrentProject(project) {
      let payload = { projects: this.projects, project };
      this.handleSetActiveProject(payload);
    },
    menuClosed() {
      this.triggerMobileMenu = false;
      const body = document.querySelector("body");
      body.classList.remove("is-fixed");
    },
    gotoHome() {
      this.$router.push({ path: "/" });
    },
    async submit() {
      this.$emit("runValidForm");

      if (this.hasError) return;
      // stote this form data in vuex we have 5 steps

      if (this.step == 2) {
        let others = [];

        this.formData.technologies.map((t) => {
          let tech = this.technologiesList.find((o) => o.id == t);
          if (tech.role_skill && tech.other_id) {
            others = [...others, tech.other_id];
          }
        });

        this.formData.others.map((tech) => {
          if (tech.role_skill && tech.other_id) {
            if (this.formData.technologies.includes(tech.other_id)) {
              others = [...others, tech.id, tech.other_id];
            } else {
              let other = this.othersTechnologiesList.find(
                (o) => o.id === tech.other_id
              );
              if (other.name === tech.name) {
                others = [...others, tech.id, tech.other_id];
              }
            }
          } else {
            others = [...others, tech.id];
          }
        });

        others = [...new Set(others)].map((o) => (o.id ? o.id : o));

        others = others.filter(
          (other) => !this.formData.technologies.includes(other)
        );

        await this.axios
          .post(this.stepsApis[this.step - 1], {
            technologies: [
              ...this.formData.technologies.map((o) => (o.id ? o.id : o)),
            ],
            others: [...new Set(others)],
            need_technology_advice: this.formData.need_technology_advice
              ? 1
              : 0,
          })
          .then(() => {
            this.next();
          })
          .catch((err) => {
            console.error(err);
          });

        this.$store.dispatch("auth/saveFormData", {
          step: this.step,
          data: this.formData,
        });
      } else {
        if (this.step != 5) {
          await this.axios
            .post(this.stepsApis[this.step - 1], this.formData)
            .then(() => {
              this.next();
            })
            .catch((err) => {
              console.error(err);
            });

          this.$store.dispatch("auth/saveFormData", {
            step: this.step,
            data: this.formData,
          });
        }
      }

      // tracking events hook
      switch (Number(this.step)) {
        case 4:
          if (window.gtag)
            window.gtag("event", "info submitted", {
              user_type: "Client",
              step_number: this.step,
              product_name: this.formData.name,
              industry: this.formData.project_type_id,
              project_description: this.formData.description,
            });
          break;
        case 1:
          var services = [
            "Team As A Service",
            "Creative Lab",
            "CTO As AService",
          ];
          var selectedServices = [];
          this.formData.services.forEach((serviceId) => {
            selectedServices.push(services[serviceId - 1]);
          });
          if (window.gtag)
            window.gtag("event", "info submitted", {
              user_type: "Client",
              step_number: this.step,
              service_selected: selectedServices,
            });
          break;
        case 2:
          var product_technologies = [];
          var other_skills = [];
          this.technologiesList.forEach((tech) => {
            this.formData.technologies.includes(tech.id) &&
              product_technologies.push(tech.name);
          });
          this.formData.others.length &&
            this.othersTechnologiesList.forEach((tech) => {
              this.formData.others.includes(tech.id) &&
                other_skills.push(tech.name);
            });
          if (window.gtag)
            window.gtag("event", "info submitted", {
              user_type: "Client",
              step_number: this.step,
              product_technologies,
              other_skills,
            });
          break;
        case 3:
          if (window.gtag)
            window.gtag("event", "info submitted", {
              user_type: "Client",
              step_number: this.step,
              hiring_needs: Number(this.formData.hiring_type)
                ? "Full time"
                : "Part time",
              build_app_from_scratch: Number(this.formData.from_scratch)
                ? true
                : false,
              consultant_needed: Number(this.formData.need_advice)
                ? true
                : false,
            });
          break;
      }
    },
    sendPageView() {
      const extractUTMParameters = (queryParams) => {
        let params = queryParams;
        let getUTMParameters = this.getUTMParameters;

        const utmParameters = {
          utm_source: params.utm_source || getUTMParameters?.utm_source,
          utm_medium: params.utm_medium || getUTMParameters?.utm_medium,
          utm_campaign: params.utm_campaign || getUTMParameters?.utm_campaign,
          utm_term: params.utm_term || getUTMParameters?.utm_term,
          utm_content: params.utm_content || getUTMParameters?.utm_content,
          gclid: params.gclid || getUTMParameters?.gclid,
          dclid: params.dclid || getUTMParameters?.dclid,
          gbraid: params.gbraid || getUTMParameters?.gbraid,
          wbraid: params.wbraids || getUTMParameters?.wbraids,
          fbclid: params.fbclid || getUTMParameters?.fbclid,
          ttclid: params.ttclid || getUTMParameters?.ttclid,
          twclid: params.twclid || getUTMParameters?.twclid,
          li_fat_id: params.li_fat_id || getUTMParameters?.li_fat_id,
        };

        this.$store.commit("shared/setUTMParameters", utmParameters);

        return utmParameters;
      };

      const utmParameters = extractUTMParameters(this.$route.query);
      const currentUrl = window.location;
      let payload = {
        ...utmParameters,
        page_name: this.$route.name + "/" + this.currentStep + "step",
        page_url: currentUrl.origin + this.$route.fullPath,
      };

      // removeNullUndefined
      for (const key in payload) {
        if (payload[key] === null || payload[key] === undefined) {
          delete payload[key];
        }
      }

      if (window.gtag) window.gtag("event", "page view", payload);
    },
    bookMeetingFunc() {
      this.currentStep = 5;
      this.$store.dispatch("auth/setStep", this.currentStep);

      this.scrollToTop();
    },
    async next() {
      ++this.currentStep;
      this.$store.dispatch("auth/setStep", this.currentStep);

      this.scrollToTop();
      if (this.currentStep == ClientSteps.PROFILE) {
        this.$router.push({ name: "clientHome" });
      }
    },
    async previous() {
      if (this.onStepWizard) {
        this.$router.push({ path: "/client-signup?campaign=15" });
        return;
      }
      if (this.currentStep != ClientSteps.SELECT_SERVICE) {
        --this.currentStep;
        this.$store.dispatch("auth/setStep", this.currentStep);
        this.scrollToTop();
      }
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    logOutToTalent() {
      this.$store.dispatch("auth/logOut").then(() => {
        this.$router.push({ path: "/talent-signup" });
        // window.location.reload();
      });
    },
    logOut() {
      this.$store.dispatch("auth/logOut").then(() => {
        this.$router.push({ path: "/login" });
        // window.location.reload();
      });
    },
  },
  watch: {
    currentStep: {
      immediate: true,
      deep: true,
      handler() {
        this.sendPageView();
      },
    },
  },
};
</script>


<style lang="scss" scoped>
header {
  box-shadow: none !important;
}

@media (min-width: 1200px) {
  .hide-lang-lg {
    display: none !important;
  }
}

.navbar-brand {
  @media (max-width: 768px) {
    width: 55px;
    margin: 0;
  }
}
</style>
