<template>
  <div>
    <ClientWizardContainer
      :step="stepNum"
      :hasError="validForm"
      :technologiesList="technologiesList"
      :othersTechnologiesList="othersTechnologiesList"
      :formData="formData"
      @runValidForm="runValidForm"

    >
      <div class="container" id="stepper-items" slot="form">
        <form id="step3">
          <h3 class="my-1 text-center font-weight-bold main-step-title">
            {{ $t("clientWizard.yourProductsTechnologies") }}
          </h3>
          <p class="mb-4 text-center gray-color-text">
            {{ $t("clientWizard.pleaseSelectAtLeastOneTechnology") }}
          </p>
          <div
            class="grid-list align-items-center checkboxes-container"
          >
            <div v-if="technologiesList.length" class="h-100">
              <input
                type="checkbox"
                id="myCheckbox0"
                :value="Number(1)"
                v-model="formData.need_technology_advice"
              />

              <label
                for="myCheckbox0"
                class="m-0 text-center select-services-layout needBox d-flex flex-column justify-content-center"
              >
                <div class="text-center d-flex flex-column align-items-center">
                  <img loading="lazy"
                    :src="
                      require('../../assets/images/technologies/information.png')
                    "
                    class="mx-auto mb-2"
                    width="50"
                    heigh="50"
                  />
                  <span>
                    {{ $t("clientWizard.notSureNeedAdvice") }}
                  </span>
                </div>
              </label>
            </div>
            <div
              v-for="(checkbox, i) in technologiesList"
              :key="i"
              class="h-100"
            >
              <input
                type="checkbox"
                :id="'myCheckbox' + i + 1"
                :value="checkbox.id"
                v-model="formData.technologies"
              />
              <label
                :for="'myCheckbox' + i + 1"
                class="m-0 text-center select-services-layout needBox d-flex flex-column justify-content-center"
              >
                <div class="text-center d-flex flex-column align-items-center">
                  <img loading="lazy"
                    :src="
                      require('../../assets/images/technologies/' +
                        getImgUrl(checkbox.image))
                    "
                    class="mx-auto mb-2"
                    width="50"
                    heigh="50"
                  />
                  <span>{{ checkbox.name }}</span>
                </div>
              </label>
            </div>
          </div>

          <div class="mb-4 col-sm-12">
            <div>
              <label class="mb-1 form-label">
                {{ $t("clientWizard.chooseOtherSkills") }}
              </label>
              <v-select
                :options="filtered"
                :filterable="false"
                @search="(query) => setSearch(query)"
                multiple
                class="style-chooser"
                :placeholder="$t('clientWizard.enterOtherSkills100Available')"
                label="name"
                v-model="othersValues"
                @input="selectOption"
              >
                <div slot="no-options">
                  {{ $t("talent.sorryNoMatchingOptions") }}
                </div>
              </v-select>
            </div>
          </div>
        </form>
      </div>
    </ClientWizardContainer>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ClientWizardContainer from "../ClientWizzardContainer/index1.vue";
import toast from "@/services/toast";

import "./style.scss";
export default {
  name: "Technologies",
  components: { ClientWizardContainer },
  props: ["stepNum"],
  data: function () {
    return {
      informationImg: "information.png",
      technologiesList: [], //ddl
      othersTechnologiesList: [],
      formData: {
        need_technology_advice: 0,
        technologies: [],
        others: [],
      },
      page: 1,
      observer: null,
      limit: 15,
      search: "",
    };
  },
   metaInfo() {
    return {
    title: `Client Registration - Step 3: Select Your Technologies`,
    };
  },
  mounted() {
    if(this.isLoggedIn && this.user) {this.getClientProjects()}
    this.getTechnologiesList();
    this.getOthersTechnologiesList();
    //  this.observer = new IntersectionObserver(this.infiniteScroll);
    this.getSavedTechnologiesData();
  },
  watch: {
    "formData.technologies": {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        if (oldValue) {
          let removeedTech = oldValue.filter((x) => !newValue.includes(x));
          removeedTech.map((item) => {
            let tech = this.technologiesList.find((t) => t.id == item);
            if (tech.other_id) {
              this.formData.others = this.formData.others.filter(
                (other) => other.id !== tech.other_id
              );
            }
          });
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      activeProject: "client/getActiveProject",
      isLoggedIn: "auth/isLoggedIn",
      user: "auth/getUser",
    }),
    filtered() {
      return this.getUniqueListBy(this.othersTechnologiesList, "name")
        ?.filter(
          (oTech) =>
            !this.technologiesList.find((tech) => tech.name === oTech.name)
        )
        .filter((skill) =>
          skill.name.toLowerCase().includes(this.search.toLowerCase())
        );
    },
    paginated() {
      return this.filtered.slice(0, this.limit);
    },
    hasNextPage() {
      return this.paginated.length < this.filtered.length;
    },
    othersValues() {
      return this.getUniqueListBy(this.formData.others, "name").filter(
        (oTech) =>
          !this.technologiesList.find((tech) => tech.name === oTech.name)
      );
    },
     validForm() {
      //not valid ==>true ===disabled ==
      // valid ==>false ==enable
      return !(
        !this.formData.technologies.length < 1 ||
        !this.formData.others.length < 1 ||
        this.formData.need_technology_advice != 0
      );
    },
  },
  methods: {
    ...mapActions({
      handleSetActiveProject: "client/handleSetActiveProject"
    }),
    getClientProjects() {  
      this.axios.get(`/api/clients/projects/minimal`).then((result) => {
        this.projects = result.data.data
        let payload = {projects: this.projects, project:  result.data.data[0] }
        if(!this.activeProject && result.data.data[0] ) this.handleSetActiveProject(payload)
      }).catch((err) => {
        console.error(err);
      });
      },
    runValidForm() {
      if (this.validForm) toast.error(this.$t('clientWizard.pleaseSelectAtLeastOneTechnology'));
    },
    getUniqueListBy(arr, key) {
      return [...new Map(arr.map((item) => [item[key], item])).values()];
    },
    setSearch(search) {
      this.search = search;
    },
    selectOption(options) {
      this.formData.others = options.map((option) => option);
    },
    getImgUrl(url) {
      if (url.includes("no_code")) {
        return "img_nocode.svg";
      } else {
        return url.split("/").pop();
      }
    },
    async getTechnologiesList() {
      try {
        await this.axios
          .get("/api/staticContent/clientTechnologies")
          .then((res) => {
            this.technologiesList = res.data.data.map((tech) => {
              return { ...tech, isOther: false };
            });
          });
      } catch (error) {
        console.error(error);
      }
    },
    async getSavedTechnologiesData() {
      if (this.$store.state.auth.token)
        try {
          await this.axios.get("/api/client/projects").then((res) => {
            let data = res.data.data;
            this.formData.need_technology_advice =
              data.need_technology_advice || 0;
            this.formData.technologies =
              data.technologies?.map((item) => item.id) || [];
            this.formData.others = data.others_technologies || [];
          });
        } catch (error) {
          console.error(error);
        }
    },
    async getOthersTechnologiesList() {
      try {
        await this.axios
          .get("/api/staticContent/clientTechnologies", {
            params: { others: 1 },
          })
          .then((res) => {
            this.othersTechnologiesList = [
              ...res.data.data.map((tech) => {
                return { ...tech, isOther: true };
              }),
            ];
          });
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
