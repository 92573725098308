<template>
  <div>
    <ClientWizardContainer
      :step="stepNum"
      :hasError="validForm"
      :formData="{ services: services }"
      @runValidForm="runValidForm"
    >
      <div class="container" id="stepper-items" slot="form">
        <div class="mb-4">
          <div class="mt-4 mb-5">
            <h3 class="my-1 text-center font-weight-bold main-step-title">
              {{ $t("clientWizard.readyToHire1") }}
            </h3>
            <p class="text-center gray-color-text">
              {{ $t("clientWizard.tellUsWhatYoureLookingFor") }}
            </p>
          </div>
          <form id="step2">
            <div
              class="row align-items-center checkboxes-container"
            >
              <div
                class="text-center col-md-4 align-self-center"
                v-for="(checkbox, i) in checkboxesData"
                :key="i"
              >
                <input
                  type="checkbox"
                  :id="'myCheckbox' + i"
                  :value="checkbox.value"
                  v-model="services"
                />
                <label
                  :for="'myCheckbox' + i"
                  class="text-center select-services-layout needBox d-flex d-md-block align-items-center"
                >
                  <div
                    class="text-center check-box-content d-flex d-md-block align-items-center w-100"
                  >
                    <div class="col-md-12 col-3">
                      <img loading="lazy" :src="checkbox.src" class="mb-md-4 tw-m-auto" />
                    </div>
                    <div class="text-center col-md-12 col-9 text-sm-start text-md-center text-lg-center">
                      <h3 class="font-size-20 font-PoppinsSemiBold">
                        {{ $t(`clientWizard.${checkbox.title}`) }}
                      </h3>
                      <p class="mb-0">
                        {{ $t(`clientWizard.${checkbox.subTitle}`) }}
                      </p>
                    </div>
                  </div>
                </label>
              </div>
            </div>
          </form>
        </div>
      </div>
    </ClientWizardContainer>
  </div>
</template>

<script>
import ClientWizardContainer from "../ClientWizzardContainer/index1.vue";
import toast from "@/services/toast";

import "./style.scss";
export default {
  components: {
    ClientWizardContainer,
  },
  props: ["stepNum"],
  data: function () {
    return {
      services: [],
      checkboxesData: [
        {
          src: require("../../assets/images/client/ic_engineering.svg"),
          title: "teamAsAService",
          subTitle: "technicalCalibersWithAReasonableCost",
          value: 1,
        },
        {
          src: require("../../assets/images/client/ic_creative-team.svg"),
          title: "creativeLab",
          subTitle:
            "providingAProjectManagerAndAProductDesignerToStartWorkingOnYourProductsIdea",
          value: 2,
        },
        {
          src: require("../../assets/images/client/ic_CTO.svg"),
          title: "CTOAsAService",
          subTitle: "technicalConsultation",
          value: 3,
        },
      ],
    };
  },
   metaInfo() {
    return {
    title: `Client Registration - Step 2: Select Your Services`,
    };
  },
  mounted() {
    this.getServicesData();
  },
  computed: {
    validForm() {
      return this.services.length < 1;
    },
  },
  methods: {
    runValidForm() {
      if (this.validForm) toast.error(this.$t('validation.selectOneService'));
    },
    async getServicesData() {
      if (this.$store.state.auth.token)
        try {
          await this.axios.get("/api/client/projects").then((res) => {
            let data = res.data.data;
            this.services =
              data.services.map((service) => service.service) || [];
          });
        } catch (error) {
          console.log(error);
        }
    },
  },
};
</script>

<style lang="scss" scoped></style>
