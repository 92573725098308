<template>
  <div class="tw-flex tw-justify-center tw-container tw-w-full tw-m-auto tw-pb-8">
    <div class="tw-m-0 sm:tw-m-4 tw-flex tw-justify-center tw-flex-1 md:tw-gap-20 tw-mx-2" :class="{'tw-bg-white tw-max-w-5xl tw-rounded-lg tw-pb-8': campaign == 15}">
      <div class="tw-w-full tw-max-w-3xl tw-mx-4 md:tw-mx-0 tw-mt-0 md:tw-mt-8 tw-px-4">
        <template v-if="campaign == 15">
          <h2 class="tw-text-xl md:tw-text-4xl tw-text-center tw-text-gray-900 tw-font-bold tw-mt-12 md:tw-mt-0">
            {{ $t('signUp.signupClient') }}
          </h2>
          <h3 class="tw-text-md tw-text-center tw-text-gray-400 tw-mt-2 tw-mb-12">
            {{ $t('signUp.signupClientSlogan') }}
          </h3>
          <NewCLientSignup />
        </template>
        <template v-else>
          <h2 class=" tw-text-2xl md:tw-text-4xl tw-text-gray-900 tw-font-semibold tw-mt-12 md:tw-mt-0">{{ $t('signUp.signupClient1') }}</h2>
          <CLientSignup />
        </template>
      </div>
      <div v-if="campaign != 15" class="md:tw-w-1/2 lg:tw-w-1/2 tw-text-center md:tw-flex tw-justify-center tw-items-start tw-hidden ">
        <img
          loading="lazy"
          class="tw-w-auto tw-max-h-[536.82px] tw-object-contain"
          src="@/assets/images/signup-design-min.png"
        />
      </div>
    </div>
  </div>
</template>
<script>
import CLientSignup from "./client.vue";
import NewCLientSignup from "./new-client.vue";

export default {
  components: {
    CLientSignup,
    NewCLientSignup,
  },
  computed: {
    campaign() {
      const { campaign, Campaign } = this.$route.query;
      return campaign || Campaign;
    },
  },
/*   watch: {
    campaign: {
      immediate: true,
      deep: true,
      handler() {
        if (this.campaign == 15 && window.location.href.includes("squadio.com")) {
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-PKT8578B');
        }
      }
    }
  }, */
};
</script>