
<template>
  <div>
    <div class="mb-4 row">
      <div class="col-12 talents-text">
        <h1 v-if="!isLoaded" class="semibold">
          {{ $t("matchedTalents.ourVettedTalents") }}
        </h1>
        <div
          class="mb-3 d-flex align-items-center justify-content-center"
          v-if="isLoaded"
        >
          <skeleton-loader-vue
            type="rect"
            :width="500"
            :height="30"
            animation="fade"
            rounded
          />
        </div>
        <p v-if="!isLoaded">
          {{ $t("matchedTalents.goodDay", { name: userName }) }}
          <span class="tw-w-28 tw-text-xs tw-text-center tw-whitespace-nowrap tw-mx-auto tw-block tw-mt-4 tw-px-3 tw-py-1 tw-rounded-full tw-border tw-text-[#422E87] tw-border-[#422E87] tw-select-none">
            {{ $t('matchedTalents.beta') }}
          </span>
        </p>
        <div
          class="mb-3 d-flex align-items-center justify-content-center"
          v-if="isLoaded"
        >
          <skeleton-loader-vue
            type="rect"
            :width="400"
            :height="30"
            animation="fade"
            rounded
          />
        </div>
      </div>
    </div>

    <div class="meetingTime" v-if="!hideMeetingTime">
      <div v-if="!isLoaded && date && !hideMeetingTime">
        {{ $t("matchedTalents.meetingNotification") }}
        <span class="ltr"> {{ getData() }} GMT </span>
      </div>
      <div
        class="mb-3 d-flex align-items-center justify-content-center"
        v-else-if="isLoaded"
      >
        <skeleton-loader-vue
          type="rect"
          :width="600"
          :height="30"
          animation="fade"
          rounded
        />
      </div>
      <div
        class="d-flex align-items-center justify-content-center"
        v-if="isLoaded"
      >
        <skeleton-loader-vue
          type="rect"
          :width="600"
          :height="30"
          animation="fade"
          rounded
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import axiosCancelMixin from '@/mixins/axiosCancelMixin.js';


export default {
  props: ["isloading"],
  mixins: [axiosCancelMixin],
  components: {},
  data() {
    return {
      userName: "",
      date: "",
      isLoaded: true,
      hideMeetingTime: false,
    };
  },
  mounted() {
    this.getBookingData();
    this.userName = JSON.parse(localStorage.getItem("user")).first_name;
  },
  watch: {
    isloading: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue) {
          this.getBookingData()
        }
      }
    }
  },
  methods: {
    getData() {
      if (this.date)
        return `${moment(this.date).format(" DD MMMM YYYY")} at ${moment(
          this.date
        ).format(" h:mm ")} `;
    },
    async getBookingData() {
      if (this.$store.state.auth.token && !this.date)
        try {
          this.isLoaded = true;
          const cancelToken = this.createCancelToken();
          await this.axios.get("/api/client/projects", { cancelToken }).then((res) => {
            
            this.date = res.data.data.meeting_start_time;
            this.hideMeetingTime = res.data.data.hide_meeting_time
            this.isLoaded = false;
          });
        } catch (error) {
          console.log(error);
        }
    },
  },
};
</script>

<style lang="scss" scoped>
.talents-text h1 {
  font-weight: 600;
  font-size: 3em;
  margin-bottom: 1.5rem;
}

@container (max-width: 768px) {
  .mb-5 {
    margin-bottom: 1rem !important;
  }

  .talents-text h1 {
    font-size: 25px;
  }

  .talents-text p {
    font-size: 12px;
    margin-bottom: 0;
  }
}

.meetingTime {
  padding: 30px;
  font-size: 22px;
  font-weight: 500;
  color: rgba(159, 157, 157, 1);
  background-color: rgba(255, 255, 255, 1);
  border-radius: 22px;
  box-shadow: 0 15px 30px 0 rgb(0 0 0 / 3%);
  margin-bottom: 20px;
  letter-spacing: 0.05rem;

  @media (max-width: 575.98px) {
    font-size: 18px;
    font-weight: 500;
  }

  .ltr {
    direction: ltr;
  }

  span {
    display: block;
    color: #292929;

    @media (max-width: 575.98px) {
      font-size: 16px;
      margin-top: 1rem;
    }
  }
}
</style>
  