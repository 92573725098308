<template>
  <div>
    <!--     <div
      class="assessmentWrapper"
      v-if="(!isSkeleton && isLoaded) || assessmentsList"
    >
      <div>
        <Carousel
          :content="child_component"
          :contentArr="assessmentsList"
          @handleChange="handlePageChange($event)"
          :rewind="false"
          :arrowStyle="activeArrow"
        />
      </div>
    </div>

    <div>
      <div class="assessmentWrapper" v-if="isSkeleton || !isLoaded">
        <div
          class="col-12 skeletonWrapper d-flex justify-content-center align-items-center"
        >
          <Skeleton />
        </div>
      </div>
    </div>
 -->
    <div class="assessmentWrapper">
      <div class="matching-skills" id="assessments-list">
        <Carousel
          v-if="!isLoaded"
          :content="assessmentVelentsComponent"
          :contentArr="assessmentsVelentsList"
          @handleChange="handlePageChange($event)"
          :rewind="false"
          :arrowStyle="activeArrow"
        />
        <div v-if="isLoaded">
          <div>
            <div
              class="col-12 skeletonWrapper d-flex justify-content-center align-items-center"
            >
              <Skeleton />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--     <div
      v-if="isSkeleton || !isLoaded"
      class="col-12 skeletonWrapper d-flex justify-content-center align-items-center"
    >
      <Skeleton />
    </div> -->
  </div>
</template>
<script>
import Carousel from "../../../../../components/shared/carousel/carousel.vue";
import CarouselContent from "./carouselContent.vue";
import assessmentVelents from "./assessmentVelents.vue";
import Skeleton from "./carouselContentSkeleton.vue";
import "./assessments.scss";

export default {
  //eslint-disable-next-line
  components: { Carousel, CarouselContent, Skeleton, assessmentVelents },
  props: [""],
  created() {
    this.isLoaded = true;
    this.$store
      .dispatch("talentProfile/getAssessmentsRequest", { page: 1 })
      .then(() => {
        this.isLoaded = false;
        this.$emit("assessments-loaded");
      });
  },

  computed: {
    console: () => console,
    assessmentsList() {
      let assessmentsList = [];
      if (this.$store.getters["talentProfile/getAssessments"].length) {
        this.$store.getters["talentProfile/getAssessments"].map((skill) => {
          skill.assessments.map((assessment) => {
            assessmentsList = [
              ...assessmentsList,
              { ...skill, assessment: assessment },
            ];
          });
        });
      }

      return assessmentsList;
    },
    assessmentsVelentsList() {

      let assessments = this.$store.getters["talentProfile/getAssessments"].filter((skill) => skill.assessments.length)

      let assessmentsVelentsList = []


      assessments.map(assessment => {
        if (assessment.type == 1) {
          let assessments = assessment.assessments.filter(a => a.type === 'mcq');
          let codeAssessments = assessment.assessments.find(a => a.type === 'code');

          assessments = assessments.map((a, i) => (i === 0 ? [a, codeAssessments] : [a]));

          assessments = assessments.map(a => {
            return a.filter(item => item != undefined) 
          });

          assessments.forEach(a => {
            let newAssessment = { ...assessment, assessments: a, assessmentData: a[0] };
            assessmentsVelentsList.push(newAssessment);
          });
        }
        else {
          assessment.assessments.map(a => {
            let newAssessment = {...assessment, assessments: [a], assessmentData: a , isSkill: true  }
            assessmentsVelentsList.push(newAssessment)
          })
        }
      })



      
      return this.$store.getters["talentProfile/getAssessments"].length
        ? assessmentsVelentsList
        : [];
    },
    isSkeleton() {
      return this.$store.getters["shared/getSkeletonStaus"];
    },
  },
  data() {
    return {
      child_component: CarouselContent,
      assessmentVelentsComponent: assessmentVelents,
      assessmentsListUpdated: [],
      activeArrow: "activeNextArrow",
      isLoaded: true,
    };
  },
  methods: {
    handleCaruoselActiveArrow(index) {
     
      if (index - 3 == this.assessmentsList?.length - 1) {
        this.activeArrow = "activePrevArrow";
      } else if (index - 3 == 0) {
        this.activeArrow = "activeNextArrow";
      } else if (this.assessmentsList?.length - 1 > index - 3 > 0) {
        this.activeArrow = "activeNextArrow activePrevArrow";
      }
    },
    handlePageChange(e) {
      this.handleCaruoselActiveArrow(e.item.index);
      // this.$store.dispatch("shared/handleSeletonStatus", true);
      // this.$store.dispatch("talentProfile/getAssessmentsRequest", {
      //   page: e.item.index + 1,
      // });
    },
  },
};
</script>
<style lang="scss">
div.assessmentWrapper {
  &.nav {
    padding: 20px !important;
    padding-right: 20px !important;
  }

  .assessment-header {
    display: flex;
    width: 100%;
    margin-bottom: 27px;
    
    @media (max-width: 576px) { 
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0.5rem;
    }
  }

  .assessment-header-title {
    font-size: 15px;
    color: #292929;
    font-weight: 700;
    display: flex;
    align-items: center;
    @media (max-width: 576px) {
      font-weight: 600 !important;
      font-size: 11px;
      display: flex;
    }
  }

  .assessment-header__action {
    margin-left: auto;
    font-size: 14px;
    color: #422e87;
    text-decoration: underline;
    font-weight: 600;
  }

  .assessment-header-text {
    width: 100%;
    text-align: left;
    @media (max-width: 576px) {
      margin-top: 0.5rem;
      font-family: 500;
      font-size: 12px;
    }
  }

  .mr-2 {
    margin-right: 1rem;
  }

  .assessment-card {
    width: 100%;
    display: flex;
    text-align: left;
    align-content: flex-start;
    flex-direction: row;

    @media (max-width: 576px) {
      flex-wrap: nowrap;
    }

    .card-50 {
      width: 50%;
      @media (max-width: 576px) {
        width: 100%;
      }
    }

    .assessment-card-data {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 65%;
    }

    .assessment-card-waiting-time {
      font-size: 12px;
      color: #9f9d9d;
      font-weight: 500;
      width: 100%;
      @media (max-width: 576px) {
        width: 90%;
      }
    }

    .assessment-card-number {
      height: 30px;
      width: 33px;
      background-color: #ffc629;
      border-radius: 30px;
      color: #fff;
      font-weight: 800;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 3px;
      margin-right: 0.5rem;
      font-size: 14px;
      line-height: 30px;
      display: flex;
      margin-top: auto;
      margin-bottom: auto;
      direction: ltr!important;

      @media (max-width: 576px) {
        height: 30px;
        width: 30px;
        margin-top: 6px;
      }
    }

    .assessment-card-name {
      font-size: 15px;
      color: #292929;
      font-weight: 600;
      word-break: break-all;
      @media (max-width: 576px) {
        font-weight: 500;
        font-size: 12px;
        word-break: break-word;
      }
    }

    .assessment-card-score-number {
      font-size: 20px;
      color: #422e87;
      font-weight: 800;
    }

    .assessment-card-score-number-total {
      font-size: 14px;
      color: #422e87;
    }

    .assessment-card-score-text {
      font-size: 14px;
      color: #9f9d9d;
      margin-left: 10px;
      font-weight: 500;
    }

    .assessment-card-action {
      margin-left: auto;
      display: flex;
      align-items: center;
      img {
        height: 30px;
        width: 30px;
      }

      .btn {
        font-weight: 600;
        height: 45px;
        width: 90px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        background-color: #422e87;
        border-radius: 12px;
        @media (max-width: 576px) {
          font-size: 14px;
          height: 23px;
          width: 69.79px;
        }
      }
      .btn:disabled {
        background-color: #e2dede;
        opacity: 1;
        color: #9f9d9d;
        border-color: #e2dede;
      }
    }
  }

  @media (max-width: 576px) {
    .assessment-card {
      width: 100%;
      &:first-child {
        margin-bottom: 1rem;
      }
    }

    .assessments-cards {
      flex-direction: column;
    }
  }
}
</style>