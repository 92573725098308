<template>
  <div class="topIntro position-relative mh-100 client-home-wrapper">
    <div class="container position-relative">
      <nav class="navbar z-index-1 navbar-expand-xl justify-content-between">
        <router-link class="cursor-pointer navbar-brand" to="/">
          <img loading="lazy" src="../../../assets/images/logo/2Asset 1.svg" alt width="120" />
        </router-link>        
        <div class="login-btn login-btn-ms-auto !tw-hidden md:!tw-flex">
          <client-dropdown-menu
            v-if="user.first_name"
            :client-name="user.first_name"
            :projects="projects"
            :active-project="activeProject"
            :has-nomination="isClientHasNomination"
            :isClientHasContractRenewal="isClientHasContractRenewal"
            @project-changed="changeCurrentProject($event)"
          />
          <LanguageSwitcher class="language-switcher-white-navbar" />
        </div>
      
        <LanguageSwitcher separator-light has-divider divider-at-start class="me-2 ms-auto" />
        <button
          class="navbar-toggler light"
          type="button"
          @click="triggerMobileMenu = !triggerMobileMenu"
        >
          <!-- <span class="navbar-toggler-icon"> -->
          <svg data-v-0315f6de="" width="20px" height="23px" viewBox="0 0 20 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g data-v-0315f6de="" id="Client" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g data-v-0315f6de="" id="02.01.squadio2-final-design" transform="translate(-1462.000000, -39.000000)"><g data-v-0315f6de="" id="Group-5" transform="translate(1462.000000, 39.000000)"><rect data-v-0315f6de="" id="Rectangle" x="0" y="0" width="20" height="2" rx="1"></rect><rect data-v-0315f6de="" id="Rectangle" x="0" y="8" width="20" height="2" rx="1"></rect><rect data-v-0315f6de="" id="Rectangle" x="0" y="16" width="20" height="2" rx="1"></rect></g></g></g></svg>
          <!-- </span> -->
        </button>
        <mobile-menu
          :is-open="triggerMobileMenu"
          logged-in-client
          @close-menu="menuClosed"
          :projects="projects"
          :active-project="activeProject"
          @changeCurrentProject="changeCurrentProject"
        >
            <li class="mobileMenu__item">
              <a
                class="px-3 nav-link"
                :class="
                  $route.name == 'clientMatching'
                    ? 'link-secondary navbar-secondary'
                    : 'nav-link'
                "
                @click="matchingNavigator('clientMatching')"
                > {{ $t("matchedTalents.matchedTalents") }}</a
              >
            </li>
            <li v-if="userName && isClientHasNomination" class="mobileMenu__item">
              <a
                class="px-3 nav-link"
                :class="
                  $route.name == 'Nominated profiles'
                    ? 'link-secondary navbar-secondary'
                    : 'nav-link'
                "
                @click="matchingNavigator('Nominated profiles')"
                >
                {{  $t('matchedTalents.nominations') }}
                </a
              >
            </li>
            <li v-if="userName && isClientHasContractRenewal" class="mobileMenu__item">
              <router-link
                class="px-3 nav-link"
                :class="
                  $route.name == 'contractsRenewal'
                    ? 'link-secondary navbar-secondary'
                    : 'nav-link'
                "
                to="/sq/client/contracts-renewal"
              >
                  {{ $t("matchedTalents.squadRenewal") }}
              </router-link>
            </li>

            
            <li v-if="userName && isClientHasInterviews" class="mobileMenu__item">
              <router-link
                class="px-3 nav-link"
                :class="
                  $route.name == 'interviewsList'
                    ? 'link-secondary navbar-secondary'
                    : 'nav-link'
                "
                to="/sq/client/interviews-list"
              >
                  {{ $t("matchedTalents.interviewsList") }}
              </router-link>
            </li>

            <li class="mobileMenu__item" v-if="user.id">
                      <button  @click="logOut">
                      {{ $t("home.logout") }}
                      </button>
            </li>
                   <li class="mobileMenu__item" v-else>
                      <router-link to="/login">
                      {{ $t("home.login") }}
                      </router-link>
                  </li>
        </mobile-menu>
      </nav>

      <div class="container py-5 align-items-center">
        <div class="text-center row align-items-center text-lg-start">
          <div class="mt-5 col-lg-7 col-12">
            <h1 class="mb-4 text-white">
              <span>
                <span class="text-yellow"> {{ $t("home.build") }} </span>
                {{ $t("home.yourTechTeam") }}
              </span>
              <span class="h2 d-block heading-cap">
                {{
                  $t(
                    "home.buildYourTechTeamWithRemoteTop-NotchTechTalentsToday"
                  )
                }}
              </span>
            </h1>
          </div>

          <div class="order-first mt-4 text-center col-lg-5 col-12 position-relative order-lg-last"
            data-animate-effect="fadeInLeft">
            <img loading="lazy" src="../../../assets/images/signup-design-min.png" alt class="img-fluid topIntroimg hero-img" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import LanguageSwitcher from "@/components/shared/languageSwitcher/languageSwitcher.vue";
import MobileMenu from "@/components/MobileMenu";
import ClientDropdownMenu from "@/components/ClientDropdownMenu";

import "./home.scss";
export default {
  components: {
    LanguageSwitcher,
    MobileMenu,
    ClientDropdownMenu,
  },
  data() {
    return {
      isCongratModalVisible: false,
      triggerMobileMenu: false,
      isLogoutMenu: false,
      userName: null,
      isProjectMenu: false,
      projects: []
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/getUser",
      activeProject: "client/getActiveProject",
      isClientHasContractRenewal: "auth/getClientHasContractRenewal",
      isClientHasNomination: "auth/getClientNominationState",
      isClientHasInterviews: "auth/getClientHasInterviews"
    }),
  },
  mounted() {
    this.getClientProjects()
    const body = document.querySelector('body');
    body.classList.remove('is-fixed');
    this.userName = JSON.parse(localStorage.getItem("user"))?.first_name;
    if (this.userName) {
      this.setClientCounters()
    }
  },
  methods: {
    ...mapActions({
      handleSetActiveProject: "client/handleSetActiveProject",
      setClientCounters: "auth/setClientCounters"
    }),
    menuClosed() {
      this.triggerMobileMenu = false;
      const body = document.querySelector('body');
      body.classList.remove('is-fixed');
    },
    toggleLogouteMenu() {
      this.isLogoutMenu = !this.isLogoutMenu;
    },
    toggleProjectMenu() {
      this.isProjectMenu = !this.isProjectMenu;
    },
    getClientProjects() {
      this.axios.get(`/api/clients/projects/minimal`).then((result) => {
        this.projects = result.data.data
        let payload = { projects: this.projects, project: result.data.data[0] }
           
           if(!this.activeProject && result.data.data[0] ) this.handleSetActiveProject(payload)
         }).catch((err) => {
            console.log(err);
         });
      
    },
    changeCurrentProject(project) {
      let payload = { projects: this.projects, project }
      this.handleSetActiveProject(payload)
    },
    logOut() {
      this.$store.dispatch("auth/logOut").then(() => {
        this.$router.push({ path: "/login" });
        // window.location.reload();
      });
    },
    matchingNavigator(to) {
      if (to == "clientMatching") {
        if (localStorage.getItem("step") > 5) {
          this.$router.push({ path: `/sq/client/matching` });
        }
        else {
          this.$router.push({ path: `/client/wizard` });
        }
      } else if (to == "Nominated profiles") {
        this.$router.push({ path: `/sq/client/nomination` });
      }

    },
  },
};
</script>

<style scoped>

.login-btn-ms-auto {
  margin-right: auto !important;
  margin-left: unset !important;
  width: 100%;
    display: flex;
    flex-direction: row-reverse;
    @media (max-width: 576px) {
      width: auto;
    }
 

}
</style>