var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"landing_wrapper",class:{
      'landing_wrapper-talent': _vm.activeTab == 'talent',
      'landing_wrapper-client': _vm.activeTab == 'client',
    }},[_c('div',{staticClass:"intro topIntro nav-banner-wrapper lg:!tw-h-full",class:{
        '!tw-h-[68rem]': _vm.activeTab == 'talent',
        '!tw-h-full': _vm.activeTab == 'client',
        
      }},[_c('Navbar',{staticClass:"z-index-20",attrs:{"active-tab":_vm.activeTab}}),_c('Banner',{attrs:{"talent":_vm.activeTab == 'talent'},on:{"toggle":_vm.toggle}})],1),(_vm.activeTab == 'client')?_c('div',{staticClass:"container tw-mt-14"},[_c('DiscountsBanner')],1):_vm._e(),(_vm.activeTab == 'client')?[(_vm.featureId4)?[_c('MeetOurTalents',{attrs:{"featureId4":_vm.featureId4}}),_c('Benefits',{attrs:{"featureId4":_vm.featureId4}}),_c('Investors',{attrs:{"featureId4":_vm.featureId4}}),_c('Testimonials',{attrs:{"featureId4":_vm.featureId4}}),_c('CaseStudies',{attrs:{"featureId4":_vm.featureId4}}),_c('Supporters',{attrs:{"featureId4":_vm.featureId4}}),_c('Podcasts',{attrs:{"featureId4":_vm.featureId4}})]:[_c('MeetOurTalents'),_c('Investors'),_c('CaseStudies'),_c('Podcasts'),_c('Supporters'),_c('Benefits'),_c('Testimonials')]]:[_c('ServiceSection',{attrs:{"talents-home":""}}),_c('StepsSection'),_c('bringBuddy')],_c('Footer',{attrs:{"active-tab":_vm.activeTab}})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }