<template>
  <div>
    <ClientWizardContainer
      :step="stepNum"
      :hasError="validForm()"
      :formData="formData"
      
    >
      <div
        class="container position-relative z-index-2"
        id="stepper-items"
        slot="form"
      >
        <form class="details-step">
          <div class="d-flex justify-content-center">
            <h2 class="mt-1 text-center font-weight-bold">
              {{ $t("clientWizard.weHaveVettedTalentsWithTheSkillsYouNeed") }}
            </h2>
          </div>
          <p class="mb-4 text-center gray-color-text">
            {{ $t("clientWizard.oneMoreStepCloserToStartYourDigitalJourney") }}
          </p>
          <div class="">
            <div class="mb-4 row">
              <label class="mb-1 col-md-5 col-12 main-label">
                {{ $t("clientWizard.yourHiringNeeds") }} 
              </label> 
              <div class="col-md-7 col-12 radio-toolbar">
                <div class="row">
                  <div class="mb-2 col-4">
                    <input
                      v-model="formData.hiring_type"
                      type="radio"
                      id="Part-time"
                      :value="2"
                    />
                    <label for="Part-time" class="text-center w-100">
                      {{ $t("clientWizard.partTime") }}
                    </label>
                  </div>
                  <div class="mb-2 col-4">
                    <input
                      v-model="formData.hiring_type"
                      type="radio"
                      id="Full-time"
                      :value="1"
                    />
                    <label for="Full-time" class="text-center w-100">{{
                      $t("clientWizard.fullTime")
                    }}</label>
                  </div>
                  <div class="mb-2 col-4">
                    <input
                      v-model="formData.hiring_type"
                      type="radio"
                      id="Depends-on-the-cost"
                      :value="3"
                    />
                    <label for="Depends-on-the-cost" class="text-center w-100">
                      {{ $t("clientWizard.DependsOnTheCost") }}
                    </label>
                  </div>
                </div>
              </div>
              <!-- ********* Validation Error*********** -->
              <VulidateError />
              <!-- ************************************* -->
            </div>
            <div class="mb-4 row">
              <label class="mb-1 col-md-5 col-12 main-label">
                {{ $t("clientWizard.areYouGoingToBuildYourAppFromScratch") }}
              </label>
              <div class="col-md-7 col-12 radio-toolbar">
                <div class="row">
                  <div class="mb-2 col-4">
                    <input
                      v-model="formData.from_scratch"
                      type="radio"
                      id="yes1"
                      :value="1"
                    />
                    <label class="text-center w-100" for="yes1">
                      {{ $t("clientWizard.Yes") }}</label
                    >
                  </div>
                  <div class="mb-2 col-4">
                    <input
                      v-model="formData.from_scratch"
                      type="radio"
                      id="no1"
                      :value="0"
                    />
                    <label class="text-center w-100" for="no1">
                      {{ $t("clientWizard.No") }}</label
                    >
                  </div>
                </div>
              </div>
              <!-- ********* Validation Error*********** -->
              <VulidateError />
              <!-- ************************************* -->
            </div>
            <div class="mb-4 row">
              <label class="mb-1 col-md-5 col-12 main-label">
                {{ $t("clientWizard.doYouNeedAConsultantsAdvice") }}
              </label>
              <div class="col-md-7 col-12 radio-toolbar">
                <div class="row">
                  <div class="mb-2 col-4">
                    <input
                      v-model="formData.need_advice"
                      type="radio"
                      id="yes"
                      :value="1"
                    />
                    <label class="text-center w-100" for="yes">{{
                      $t("clientWizard.Yes")
                    }}</label>
                  </div>
                  <div class="mb-2 col-4">
                    <input
                      v-model="formData.need_advice"
                      type="radio"
                      id="no"
                      :value="0"
                    />
                    <label class="text-center w-100" for="no">{{
                      $t("clientWizard.No")
                    }}</label>
                  </div>
                </div>
              </div>
              <!-- ********* Validation Error*********** -->
              <VulidateError />
              <!-- ************************************* -->
            </div>
          </div>
        </form>
      </div>
    </ClientWizardContainer>
  </div>
</template>

<script>
import VulidateError from "../../components/shared/VulidateError/vulidateError.vue";
import ClientWizardContainer from "../ClientWizzardContainer/index.vue";
import "./style.scss";
export default {
  name: "Details",
  props: ["stepNum"],
  components: { VulidateError, ClientWizardContainer },
  data: function () {
    return {
      formData: {
        hiring_type: "",
        from_scratch: "",
        need_advice: "",
      },
    };
  },
  mounted() {
    this.getDetailsData();
  },
  metaInfo() {
    return {
    title: `Client Registration - Step 4: Select Your Project Details`,
    };
  },
  methods: {
    validForm() {
      return (
        this.formData.hiring_type == null ||
        this.formData.from_scratch == null ||
        this.formData.need_advice == null
      );
    },
    async getDetailsData() {
      if (this.$store.state.auth.token)
        try {
          await this.axios.get("/api/client/projects").then((res) => {
            let data = res.data.data;
            this.formData.hiring_type = data.hiring_type;
            this.formData.from_scratch = data.from_scratch;
            this.formData.need_advice = data.need_advice;
          });
        } catch (error) {
          console.log(error);
        }
    },
  },
};
</script>


